/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */

import React, { useState } from "react";
import {
  Container,
  Accordion,
  Card,
  Button,
  //useAccordionToggle,
  ToggleButtonGroup,
  ToggleButton,
  Form,
  Nav,
  ButtonGroup
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCaretDown, faClock, faThumbsUp, faChartLine, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
//import onClickOutside from "react-onclickoutside";
import MediaQuery from "react-responsive";

library.add(faCaretDown, faClock, faThumbsUp, faChartLine, faTimesCircle);



function NewsConfig(props) {
  
  var sortbyStrings = ["", "RECENCY", "POPULARITY", "VIRALITY"];
  var periodStrings = ["", "DAY", "WEEK", "MONTH"];
  var sortby = parseInt(props.sortby);
  var period = parseInt(props.period);
  var about = "";
  if(props.about.length>0) {about = props.about.toUpperCase();} else {about="";}
  // console.log("about=" + about);
  // console.log("props.about=" + props.about);

  const [showX, setShowX] = useState(false);


  // setaboutString(about);
 

  return (
    <Container fluid className="bwr-min-width text-center clip-padding">
      <div
        className="fill-parent bwr-notice"
        onClick={() => {
          window.location = "topics";
        }}
      >
        Show news by TOPIC
      </div>
      <div className="bwr-notice-separator fill-parent"></div>
      <Card className="fill-parent news-configurator-button-colors clip-border-radius clip-border">
        <MediaQuery query="(max-width: 600px)">
          <Card.Header>
            <Form>
              <div className="button-config-wrapper">
                <Form.Group
                  inline="true"
                  controlId="about"
                  className="news-configurator-table"
                >
                  <Form.Control
                    name="about"
                    defaultValue={about}
                    className="news-configurator-textfield"
                    type="search"
                    placeholder="Search for..."
                  />
                  {/* <ToggleButtonGroup
                    type="radio"
                    name="sortby"
                    className="button-group"
                    defaultValue={sortby}
                  >
                    <ToggleButton className="button-toggle" value={1}>
                      <FontAwesomeIcon icon="clock" size="sm" />
                    </ToggleButton>
                    <ToggleButton className="button-toggle" value={2}>
                      <FontAwesomeIcon icon="thumbs-up" size="sm" />
                    </ToggleButton>
                    <ToggleButton className="button-toggle" value={3}>
                      <FontAwesomeIcon icon="chart-line" size="sm" />
                    </ToggleButton>
                  </ToggleButtonGroup> */}
                  <Button type="submit" className="button-main">
                    GO
                  </Button>
                </Form.Group>
              </div>
            </Form>
          </Card.Header>
        </MediaQuery>
        <MediaQuery query="(min-width: 601px)">
          <Card.Header>
            <Form>
              <div className="button-config-wrapper">
                <Form.Group
                  inline="true"
                  controlId="about"
                  className="news-configurator-table"
                >
                  <Form.Control
                    name="about"
                    defaultValue={about}
                    className="news-configurator-textfield"
                    type="text"
                    placeholder="Search for..."
                  />
                  <Button type="submit" className="button-main">
                    GO
                  </Button>
                  <Form.Label className="news-configurator-label">
                    Sort by
                  </Form.Label>
                  {/* <ToggleButtonGroup
                    type="radio"
                    name="sortby"
                    className="button-group"
                    defaultValue={sortby}
                  >
                    <ToggleButton className="button-toggle" value={1}>
                      Recency
                    </ToggleButton>
                    <ToggleButton className="button-toggle" value={2}>
                      Popularity
                    </ToggleButton>
                    <ToggleButton className="button-toggle" value={3}>
                      Virality
                    </ToggleButton>
                  </ToggleButtonGroup> */}
                  <ButtonGroup className="flex-row button-group">
                    <Button className="button-toggle" href={"/news?sortby=1&about=" + about}>
                      Recency
                    </Button> 
                    <Button className="button-toggle" href={"/news?sortby=2&about=" + about}>
                      Popularity
                    </Button>
                    <Button className="button-toggle" href={"/news?sortby=3&about=" + about}>
                      Virality
                    </Button>
                  </ButtonGroup>
                </Form.Group>
              </div>
            </Form>
          </Card.Header>
        </MediaQuery>

        {/* <Accordion.Collapse>
            <Card.Body className="light-padding bwr-max-width-center">
              <Form>
                <Form.Group
                  inline="true"
                  controlId="about"
                  className="news-configurator-table"
                >
                  <Form.Label className="news-configurator-label">
                    ABOUT
                  </Form.Label>
                  <Form.Control
                    name="about"
                    defaultValue={about}
                    className="news-configurator-textfield"
                    type="text"
                    placeholder="E.g. elections"
                  />
                </Form.Group>
                <Form.Group
                  inline="true"
                  controlId="sortby"
                  className="news-configurator-table"
                >
                  <Form.Label className="news-configurator-label">
                    SORT BY
                  </Form.Label>
                  <ToggleButtonGroup
                    type="radio"
                    name="sortby"
                    className="button-group"
                    defaultValue={sortby}
                  >
                    <ToggleButton className="button-toggle" value={1}>
                      Recency
                    </ToggleButton>
                    <ToggleButton className="button-toggle" value={2}>
                      Popularity
                    </ToggleButton>
                    <ToggleButton className="button-toggle" value={3}>
                      Virality
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Form.Group>
                <Form.Group
                  inline="true"
                  controlId="period"
                  className="news-configurator-table"
                >
                  <Form.Label className="news-configurator-label">
                    FOR THIS
                  </Form.Label>
                  <ToggleButtonGroup
                    type="radio"
                    name="period"
                    className="button-group"
                    defaultValue={period}
                  >
                    <ToggleButton className="button-toggle" value={1}>
                      Day
                    </ToggleButton>
                    <ToggleButton className="button-toggle" value={2}>
                      Week
                    </ToggleButton>
                    <ToggleButton className="button-toggle" value={3}>
                      Month
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Form.Group>
                <Form.Group
                  controlId="submit-button"
                  className="news-configurator-table"
                >
                  <Button type="submit" className="button-main">
                    GO
                  </Button>
                </Form.Group>
              </Form>
            </Card.Body>
          </Accordion.Collapse> */}
      </Card>
    </Container>
  );
}

export default NewsConfig;