import React from "react";
import { Container } from "react-bootstrap";
import { Topics3Cols,Topics7Cols } from ".";

function Topic(props) {

  return (
    <Container
      fluid
      className="clip-padding bwr-min-width text-center"
      style={{ marginTop: "45px" }}
    >{!props.cluster ? <div>No Cluster found.</div> :
      (
        props.cols === "3" ? (
            <Topics3Cols
              key={props.cluster.id}
              memberids={props.cluster.memberids}
              title={props.cluster.title}
              index={props.cluster.id}
              published={props.cluster.updatedAt}
              virality={props.cluster.avgVirality}
              newest={props.cluster.newestDate}
              single={true}
              thumbnail={props.thumbnail}
            />
          ) : (
            <Topics7Cols
              key={props.cluster.id}
              memberids={props.cluster.memberids}
              title={props.cluster.title}
              index={props.cluster.id}
              published={props.cluster.updatedAt}
              virality={props.cluster.avgVirality}
              newest={props.cluster.newestDate}
              single={true}
              thumbnail={props.thumbnail}
            />
          )
      )}
    </Container>
  );

}

export default Topic;