import React from "react";
import app from "../base";
import { Container, Row, Col } from "react-bootstrap";

function AdminNav(props) {

	return (
    <Container fluid className="bwr-min-width clip-padding">
      <div className="cluster-header admin-nav-bar">
        <Row className="clip-margin">
          {props.page === "new" ? (
            <Col className="cluster-header admin-nav-item-selected">
              <a href="/admin">New</a>
            </Col>
          ) : (
            <Col className="cluster-header admin-nav-item">
              <a href="/admin">New</a>
            </Col>
          )}
          {props.page === "existing" ? (
            <Col className="cluster-header admin-nav-item-selected">
              <a href="/admin-existing">Existing</a>
            </Col>
          ) : (
            <Col className="cluster-header admin-nav-item">
              <a href="/admin-existing">Existing</a>
            </Col>
          )}
          {props.page === "rest" ? (
            <Col className="cluster-header admin-nav-item-selected">
              <a href="/admin-rest">Rest</a>
            </Col>
          ) : (
            <Col className="cluster-header admin-nav-item">
              <a href="/admin-rest">Rest</a>
            </Col>
          )}
          <Col className="cluster-header admin-nav-item">
            <a href="/news" onClick={() => app.auth().signOut()}>
              Exit
            </a>
          </Col>
        </Row>
        <Row className="clip-padding clip-margin">
          <Col className="news-card-separator-admin-nav"></Col>
        </Row>
      </div>
    </Container>
  );
}

export default AdminNav;