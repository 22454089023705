import React from "react";
import {
  Container,
  Row, Col
} from "react-bootstrap";
import { NewsCard } from ".";
import Async from "react-async";
import api from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

library.add(faSpinner);

function Topics3Cols(props) {
  function timeDiffCalc(dateFuture, dateNow) {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    // console.log("calculated days", days);

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    // console.log("calculated hours", hours);

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;
    // console.log("minutes", minutes);

    let difference = "";
    if (days > 0) {
      difference += days === 1 ? `${days} day ` : `${days} days `;
    } else if (hours > 0) {
      difference +=
        hours === 0 || hours === 1 ? `${hours} hr ` : `${hours} hrs `;
    } else if (minutes > 0) {
      difference +=
        minutes === 0 || hours === 1 ? `${minutes} min` : `${minutes} mins`;
    }

    return difference;
  }

  function daysDiff(dateFuture, dateNow) {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;
    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);

    return days;
  }

  function publishedFormat(date) {
    var formattedDate = "";

    var pubDate = date;
    var nowDate = new Date();

    formattedDate = timeDiffCalc(nowDate, pubDate) + " ago";

    return formattedDate;
  }

  // function dropNulls(arrayRef) {
  //   let array = [];
  //   // eslint-disable-next-line no-unused-vars
  //   let i = 0;
  //   for (let element of arrayRef) {
  //     if (element === null) {
  //       i++;
  //     } else array.push(element);
  //   }
  //   return array;
  // }

  // function compare(a, b) {
  //   if ( (!a===null) && (!b===null)) {
  //     if (new Date(a.published) > new Date(b.published)) return -1;
  //     if (new Date(b.published) > new Date(a.published)) return 1;
  //     return 0;
  //   } else return 0;
  // }

  let dayThreshold = 3;
  let moreCounts = [];
  let heights = [];

  function compare(a, b) {
    //console.log(a.published);
    if (new Date(a.published) > new Date(b.published)) return -1;
    if (new Date(b.published) > new Date(a.published)) return 1;
    return 0;
  }

  //console.log(props);

  const allItems = async () => {
    
    let bluest = await api.getClusterStoriesByBias(props.index, 0).catch((err) => {console.log(err)})
    let blue = await api.getClusterStoriesByBias(props.index, 1).catch((err) => {console.log(err)})
    let bluesUnsorted = [].concat(bluest.data.data, blue.data.data);
    let blues = [...bluesUnsorted].sort(compare);
    //console.log(blues)

    let bluish = await api.getClusterStoriesByBias(props.index, 2).catch((err) => {console.log(err)})
    let white = await api.getClusterStoriesByBias(props.index, 3).catch((err) => {console.log(err)})
    let reddish = await api.getClusterStoriesByBias(props.index, 3).catch((err) => {console.log(err)})
    let whitesUnsorted = [].concat(bluish.data.data, white.data.data, reddish.data.data)
    let whites = [...whitesUnsorted].sort(compare);

    let red = await api.getClusterStoriesByBias(props.index, 5).catch((err) => {console.log(err)})
    let reddest = await api.getClusterStoriesByBias(props.index, 6).catch((err) => {console.log(err)})
    let redsUnsorted = [].concat(red.data.data, reddest.data.data);
    let reds = [...redsUnsorted].sort(compare);
    
    let blueMembers = blues.map((item) =>
      <NewsCard
        key={item.id}
        value={item._id}
        bwrColor="blue"
        source={item.source}
        url={item.url}
        sourcetype={item.type}
        title={item.title}
        img={item.image}
        fblikes={item.fblikes}
        published={item.published}
        virality={item.virality}
        sortby={1}
        type="topics"
        cluster={props.index}
        thumbnail={props.thumbnail}
      />
    );

    let whiteMembers = whites.map((item) =>
      <NewsCard
        key={item.id}
        value={item._id}
        bwrColor="white"
        source={item.source}
        url={item.url}
        sourcetype={item.type}
        title={item.title}
        img={item.image}
        fblikes={item.fblikes}
        published={item.published}
        virality={item.virality}
        sortby={1}
        type="topics"
        cluster={props.index}
        thumbnail={props.thumbnail}
      />
    );

    let redMembers = reds.map((item) =>
      <NewsCard
        key={item.id}
        value={item._id}
        bwrColor="red"
        source={item.source}
        url={item.url}
        sourcetype={item.type}
        title={item.title}
        img={item.image}
        fblikes={item.fblikes}
        published={item.published}
        virality={item.virality}
        sortby={1}
        type="topics"
        cluster={props.index}
        thumbnail={props.thumbnail}
      />
    );

    let firstMembers = [blueMembers[0], whiteMembers[0], redMembers[0]];
  
    let itemAges = [];
    if (blueMembers[0]) { 
      itemAges[0] = daysDiff(new Date(), new Date(blueMembers[0].props.published)); 
    } else itemAges[0] = null;
    if (whiteMembers[0]) { 
      itemAges[1] = daysDiff(new Date(), new Date(whiteMembers[0].props.published)); 
    } else itemAges[1] = null;
    if (redMembers[0]) { 
      itemAges[2] = daysDiff(new Date(), new Date(redMembers[0].props.published)); 
    } else itemAges[2] = null;

    if (blueMembers.length > 1) {
      moreCounts[0] = blueMembers.length - 1;
    } else {
      moreCounts[0] = 0;
    }
    if (whiteMembers.length > 1) {
      moreCounts[1] = whiteMembers.length - 1;
    } else {
      moreCounts[1] = 0;
    }
    if (redMembers.length > 1) {
      moreCounts[2] = redMembers.length - 1;
    } else {
      moreCounts[2] = 0;
    }

    let max = Math.max(moreCounts[0], moreCounts[1], moreCounts[2]);
    let min = Math.min(moreCounts[0], moreCounts[1], moreCounts[2]);

    let minHeight = 30;
    let maxHeight;
    if (max < 5) maxHeight = minHeight + 10;
    else {
      maxHeight = minHeight + 30;
      if (max > 10) maxHeight = minHeight + 50;
      if (max > 50) maxHeight = minHeight + 75;
      if (max > 100) maxHeight = minHeight + 100;
    }
    
    for (let i = 0; i < moreCounts.length; i++) {
      heights[i] = minHeight + ((moreCounts[i] - min) / max) * (maxHeight - minHeight);
    }

    let response = {
      blueItems: blueMembers,
      whiteItems: whiteMembers,
      redItems: redMembers,
      firstRow: firstMembers,
      itemAges: itemAges,
      moreCounts: moreCounts,
      heights: heights
    }

    return response;
  }
    
  return (
    <Container fluid className="bwr-min-width clip-padding">
      {/* SHOWING ALL TOPICS */}
      {!props.single ? (
        <div>
          <Row className="clip-margin">
            <Col className="clip-padding topic-header">
              <div
                onClick={() => {
                  window.location = "topic?id=" + props.index;
                }}
              >
                {props.title}
              </div>
            </Col>
          </Row>
          <Row className="clip-padding clip-margin">
            <Col className="clip-padding cluster-date">
              Newest story from {publishedFormat(new Date(props.newest))}
            </Col>
          </Row>
          <Row className="medium-padding clip-margin">
            <Col className="news-card-separator-white"></Col>
          </Row>
          <Row xs="3" sm="3" className="light-side-padding clip-margin">
            <Col className="clip-padding col-heading-blue">blue</Col>
            <Col className="clip-padding col-heading-white">white</Col>
            <Col className="clip-padding col-heading-red">red</Col>
          </Row>
          <Async promiseFn={allItems}>
            <Async.Loading>
              <center>
                <FontAwesomeIcon icon="spinner" pulse /> Loading {props.title}
              </center>
            </Async.Loading>
              <Async.Resolved>
                {(data) =>
                    data ? (
                      <div>
                        <Row xs="3" sm="3" className="light-side-padding clip-margin">
                          <Col className="clip-padding">
                            {data.itemAges[0] > dayThreshold ? (
                              <div className="cluster-old-blue">
                                Nothing new <br/> for {data.itemAges[0]} days
                              </div>
                            ) : null}
                            {data.firstRow[0]}
                          </Col>
                          <Col className="clip-padding">
                            {data.itemAges[1] > dayThreshold ? (
                              <div className="cluster-old-white">
                                Nothing new <br/> for {data.itemAges[1]} days
                              </div>
                            ) : null}
                            {data.firstRow[1]}
                          </Col>
                          <Col className="clip-padding">
                            {data.itemAges[2] > dayThreshold ? (
                              <div className="cluster-old-red">
                                Nothing new <br/> for {data.itemAges[2]} days
                              </div>
                            ) : null}
                            {data.firstRow[2]}
                          </Col>
                        </Row>
                        <Row xs="3" sm="3" className="light-side-padding clip-margin">
                        {data.moreCounts[0] ? (
                          <Col className="clip-padding">
                            <div
                              onClick={() => {
                                window.location = "topic?id=" + props.index;
                              }}
                              className="more-cluster-blue"
                              style={{ height: heights[0] + "px" }}
                            >
                              +{data.moreCounts[0]} more
                            </div>
                          </Col>
                        ) : (
                          <Col className="clip-padding">
                            <div className="more-cluster-transparent"></div>
                          </Col>
                        )}
                        {data.moreCounts[1] ? (
                          <Col className="clip-padding">
                            <div
                              onClick={() => {
                                window.location = "topic?id=" + props.index;
                              }}
                              className="more-cluster-white"
                              style={{ height: heights[1] + "px" }}
                            >
                              +{data.moreCounts[1]} more
                            </div>
                          </Col>
                        ) : (
                          <Col className="clip-padding">
                            <div className="more-cluster-transparent"></div>
                          </Col>
                        )}
                        {data.moreCounts[2] ? (
                          <Col className="clip-padding">
                            <div
                              onClick={() => {
                                window.location = "topic?id=" + props.index;
                              }}
                              className="more-cluster-red"
                              style={{ height: heights[2] + "px" }}
                            >
                              +{data.moreCounts[2]} more
                            </div>
                          </Col>
                        ) : (
                          <Col className="clip-padding">
                            <div className="more-cluster-transparent"></div>
                          </Col>
                        )}
                        </Row>
                      </div>      
                    ) : null
                }
              </Async.Resolved>
            <Async.Rejected>{(error) => error.message}</Async.Rejected>
          </Async>
        </div>
      ) : props.thumbnail ? (
        <Async promiseFn={allItems}>
            <Async.Loading>
              <center>
                <FontAwesomeIcon icon="spinner" pulse /> Loading {props.title}
              </center>
            </Async.Loading>
              <Async.Resolved>
                {(data) =>
                  data ? (
                    <div>
                      <Row
                        xs="3"
                        sm="3"
                        className="light-side-padding clip-margin"
                        style={{ marginTop: "-80px" }}
                      >
                        <Col className="clip-padding">
                          {data.itemAges[0] > dayThreshold ? (
                            <div className="cluster-old-blue">
                              Nothing new <br/> for {data.itemAges[0]} days
                            </div>
                          ) : null}
                          {data.firstRow[0]}
                        </Col>
                        <Col className="clip-padding">
                          {data.itemAges[1] > dayThreshold ? (
                            <div className="cluster-old-white">
                              Nothing new <br/> for {data.itemAges[1]} days
                            </div>
                          ) : null}
                          {data.firstRow[1]}
                        </Col>
                        <Col className="clip-padding">
                          {data.itemAges[2] > dayThreshold ? (
                            <div className="cluster-old-red">
                              Nothing new <br/> for {data.itemAges[2]} days
                            </div>
                          ) : null}
                          {data.firstRow[2]}
                        </Col>
                      </Row>
                      <Row xs="3" sm="3" className="light-side-padding clip-margin">
                        {data.moreCounts[0] ? (
                          <Col className="clip-padding">
                            <div className="more-cluster-blue">+{data.moreCounts[0]} more</div>
                          </Col>
                        ) : (
                          <Col className="clip-padding">
                            <div className="more-cluster-transparent"></div>
                          </Col>
                        )}
                        {data.moreCounts[1] ? (
                          <Col className="clip-padding">
                            <div className="more-cluster-white">+{data.moreCounts[1]} more</div>
                          </Col>
                        ) : (
                          <Col className="clip-padding">
                            <div className="more-cluster-transparent"></div>
                          </Col>
                        )}
                        {data.moreCounts[2] ? (
                          <Col className="clip-padding">
                            <div className="more-cluster-red">+{data.moreCounts[2]} more</div>
                          </Col>
                        ) : (
                          <Col className="clip-padding">
                            <div className="more-cluster-transparent"></div>
                          </Col>
                        )}
                      </Row>
                    </div>
                  ) : null
                }
              </Async.Resolved>
            <Async.Rejected>{(error) => error.message}</Async.Rejected>
          </Async>
      ) : (
        <Async promiseFn={allItems}>
          <Async.Loading>
            <center>
              <FontAwesomeIcon icon="spinner" pulse /> Loading {props.title}
            </center>
          </Async.Loading>
          <Async.Resolved>
            {(data) =>
              data ? (
                <Row
                  xs="3"
                  sm="3"
                  className="light-side-padding clip-margin"
                  style={{ marginTop: "70px" }}
                >
                  <Col className="clip-padding">
                    {data.itemAges[0] > dayThreshold ? (
                      <div className="cluster-old-blue">
                        <p>Nothing new <br/> for {data.itemAges[0]} days</p>
                      </div>
                    ) : null}
                    {data.blueItems}
                  </Col>
                  <Col className="clip-padding">
                    {data.itemAges[1] > dayThreshold ? (
                      <div className="cluster-old-white">
                        Nothing new <br/> for {data.itemAges[1]} days
                      </div>
                    ) : null}
                    {data.whiteItems}
                  </Col>
                  <Col className="clip-padding">
                    {data.itemAges[2] > dayThreshold ? (
                      <div className="cluster-old-red">
                        Nothing new <br/> for {data.itemAges[2]} days
                      </div>
                    ) : null}
                    {data.redItems}
                  </Col>
                </Row>
                ) : null
            }
          </Async.Resolved>
          <Async.Rejected>{(error) => error.message}</Async.Rejected>
        </Async>
      )}
    </Container>
  );
}

export default Topics3Cols;