import React from "react";
//import api from "../api";
import { Container } from "react-bootstrap";
import { HeaderBar } from "../components";

function ShowNewsStory() {
  return (
    <Container fluid className="clip-padding news-padding-top">
      <HeaderBar page="news"/>
      <p>Here is one specific news story</p>
    </Container>
  );
}

export default ShowNewsStory;
