import React,{ useState, useContext } from "react";
import api from "../api";
import { Container } from "react-bootstrap";
import { AdminCluster3Cols, AdminCluster7Cols, AdminNewCluster } from ".";
import { AllTopicsContext } from "./AllTopicsContext";

function AdminClusters(props) {

  const loadClusters = useContext(AllTopicsContext);

  const [clusters, setClusters] = useState(props.items);
  
  function removeCluster(cluster) {
    console.log("Removing cluster " + cluster);
    let newClusters = [...clusters];
    for (var i = 0; i < newClusters.length; i++) {
      if (newClusters[i][0] === cluster) {
        newClusters.splice(i,1);
      }
    }
    setClusters(newClusters);
  }

  function dropStory(cluster, story) {
    console.log("Dropping story " + story + " from cluster " + cluster);
    let newClusters = [...clusters];
    for(var i = 0;i < newClusters.length;i++) {
      if(newClusters[i][0] === cluster) {
        for(var j = 1;j < newClusters[i].length;j++) {
          if(newClusters[i][j].id === story) {
            console.log("Found at position " + j + " of " + newClusters[i].length);
            newClusters[i].splice(j,1);
            //console.log("New size of cluster " + cluster + ": " + newClusters[i].length)
          } 
        }
      }
    }
    setClusters(newClusters);
    //console.log(clusters);
    //setDummy("New Dummy");
  }

  function hideStory(cluster, story) {
    console.log("Hiding story " + story);
    const result = api.hideStory({ id: story }).catch((err) => {
      console.log(err);
    });
    dropStory(cluster, story);
    return result;
  }

  function publishCluster(cluster, title) {
    console.log("Publishing cluster " + cluster + " with title " + title);
    let newClusters = [...clusters];
    for(var i = 0;i < newClusters.length;i++) {
      if(newClusters[i][0] === cluster) {
        //console.log("Publishing...");
        let toPublish = [];
        let memberids = [];

        for (var j = 1; j < newClusters[i].length; j++) {
          memberids.push(newClusters[i][j].id);
        }

        toPublish[0] = title;
        toPublish[1] = [...memberids];
        console.log(toPublish);
        // eslint-disable-next-line no-unused-vars
        const result = api.addCluster(toPublish)
            .catch((err) => {
              console.log(err);
            });
        
        newClusters.splice(i,1);
      }
    }
    setClusters(newClusters);
  }

  function publishEmptyCluster(title) {
    console.log("Publishing empty cluster with title " + title);
    let toPublish = [];
    toPublish[0] = title;
    console.log(toPublish);
    // eslint-disable-next-line no-unused-vars
    const result = api.addCluster(toPublish).catch((err) => {
      console.log(err);
    });
  }

  function addToCluster(cluster, title) {
    console.log("Adding to cluster " + cluster + " with title '" + title + "'");
    let newClusters = [...clusters];
    for(var i = 0;i < newClusters.length;i++) {
      if(newClusters[i][0] === cluster) {
        console.log("Publishing...");
        
        let toPublish = [];

        let memberids = [];
        for (var j = 1; j < newClusters[i].length; j++) {
          memberids.push(newClusters[i][j].id)
        }
        
        toPublish[0] = title;
        toPublish[1] = [...memberids];
        console.log(toPublish);
        // eslint-disable-next-line no-unused-vars
        const result = api.addToCluster(toPublish)
          .catch((err) => {
            console.log(err);
          });

        newClusters.splice(i,1);
      }
    }
    setClusters(newClusters);
  }

  return (
    <Container fluid className="clip-padding bwr-min-width text-center">
      <AdminNewCluster publishfunc={publishEmptyCluster} />
      {/* {dummy} */}
      {clusters.map((item) =>
        props.cols === "3" ? (
          <AdminCluster3Cols
            key={item[0]}
            members={item}
            index={item[0]}
            removefunc={removeCluster}
            publishfunc={publishCluster}
            addtofunc={addToCluster}
            dropfunc={dropStory}
            hidefunc={hideStory}
            existingClusters={loadClusters}
          />
        ) : (
          <AdminCluster7Cols
            key={item[0]}
            members={item}
            index={item[0]}
            removefunc={removeCluster}
            publishfunc={publishCluster}
            addtofunc={addToCluster}
            dropfunc={dropStory}
            hidefunc={hideStory}
            existingClusters={loadClusters}
          />
        )
      )}
    </Container>
  );

}

export default AdminClusters;