import React from 'react';
import api from "../api";
import Async from "react-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

library.add(faSpinner);

const AllTopicsContext = React.createContext();

const AllTopicsProvider = (props) => {
  
  const clusters = async () =>
      await api.getClusters()
      .catch((err) => {
        console.log(err);
      });

  return (
    <Async promiseFn={clusters}>
      <Async.Loading>
        <center>
          <FontAwesomeIcon icon="spinner" pulse /> Loading Topics
        </center>
      </Async.Loading>
      <Async.Resolved>
        {(data) =>
          data.data ? (
            <AllTopicsContext.Provider value={data.data.data}>
              {props.children}
            </AllTopicsContext.Provider>
          ) : (
            <div className="bwr-error">Nope</div>
          )
        }
      </Async.Resolved>
      <Async.Rejected>{(error) => error.message}</Async.Rejected>
    </Async>
  );
};
	

export { AllTopicsContext, AllTopicsProvider };