import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function SourceTable7Cols(props) {

  const bluestItems = props.items.map((item) =>
    item.bias === 0 ? (
      <div className="hiw-source-list" key={item.id}>
        <a
          href={"http://" + item.source_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.source}
        </a>
      </div>
    ) : null
  );

  const blueItems = props.items.map((item) =>
    item.bias === 1 ? (
      <div className="hiw-source-list" key={item.id}>
        <a
          href={"http://" + item.source_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.source}
        </a>
      </div>
    ) : null
  );

  const bluishItems = props.items.map((item) =>
    item.bias === 2 ? (
      <div className="hiw-source-list" key={item.id}>
        <a
          href={"http://" + item.source_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.source}
        </a>
      </div>
    ) : null
  );

  const whiteItems = props.items.map((item) =>
    item.bias === 3 ? (
      <div className="hiw-source-list" key={item.id}>
        <a
          href={"http://" + item.source_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.source}
        </a>
      </div>
    ) : null
  );

  const reddishItems = props.items.map((item) =>
    item.bias === 4 ? (
      <div className="hiw-source-list" key={item.id}>
        <a
          href={"http://" + item.source_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.source}
        </a>
      </div>
    ) : null
  );

  const redItems = props.items.map((item) =>
    item.bias === 5 ? (
      <div className="hiw-source-list" key={item.id}>
        <a
          href={"http://" + item.source_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.source}
        </a>
      </div>
    ) : null
  );

  const reddestItems = props.items.map((item) =>
    item.bias === 6 ? (
      <div className="hiw-source-list" key={item.id}>
        <a
          href={"http://" + item.source_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.source}
        </a>
      </div>
    ) : null
  );

  return (
    <Container fluid className="bwr-min-width text-center">
      <Row md="7" lg="7" xl="7" className="light-side-padding bottom-space">
        <Col className="clip-padding col-heading-blue">bluest</Col>
        <Col className="clip-padding col-heading-blue">blue</Col>
        <Col className="clip-padding col-heading-white">bluish</Col>
        <Col className="clip-padding col-heading-white">white</Col>
        <Col className="clip-padding col-heading-white">reddish</Col>
        <Col className="clip-padding col-heading-red">red</Col>
        <Col className="clip-padding col-heading-red">reddest</Col>
      </Row>
      <Row md="7" lg="7" xl="7" className="light-side-padding">
        <Col className="clip-padding">{bluestItems}</Col>
        <Col className="clip-padding">{blueItems}</Col>
        <Col className="clip-padding">{bluishItems}</Col>
        <Col className="clip-padding">{whiteItems}</Col>
        <Col className="clip-padding">{reddishItems}</Col>
        <Col className="clip-padding">{redItems}</Col>
        <Col className="clip-padding">{reddestItems}</Col>
      </Row>
    </Container>
  );
}

export default SourceTable7Cols;