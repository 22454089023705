import React from "react";
import { Container } from "react-bootstrap";
import Async from "react-async";
import api from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { AdminEditCluster3Cols, AdminEditCluster7Cols } from ".";

library.add(faSpinner);

function TopicsLoader(props) {

  //console.log(props);

  const members = async () => {
    let members = [];
    let max = 0;
    if (props.memberids.length > 30) { max = 30 } else {max = props.memberids.length}
    for (var i=1; i <= max; i++) {
      let story = await api.getStory(props.memberids[props.memberids.length - i]).catch((err) => {console.log(err);})
      members.push(story.data.data);
    }
    //console.log(members);
    return members;
  }

  // const members = () => api.getStories(props.memberids)
  //   .catch((err) => {
  //     console.log(err);
  //   });

  // const members = () => api.getStoriesFromCluster(props.index)
  // .catch((err) => {
  //   console.log(err);
  // });

  return (
    <Container
      id="mainstage"
      fluid
      className="clip-padding bwr-min-width text-center"
    >
      <Async promiseFn={members}>
        <Async.Loading>
          <center>
            <FontAwesomeIcon icon="spinner" pulse /> Loading {props.title}
          </center>
        </Async.Loading>
        <Async.Resolved>
          {(data) =>
            data ? (
              props.cols === "3" ? (
                <AdminEditCluster3Cols
                  key={props.index}
                  members={data}
                  index={props.index}
                  title={props.title}
                  deletefunc={props.deletefunc}
                  postfunc={props.postfunc}
                  dropfromfunc={props.dropfromfunc}
                  edittitlefunc={props.edittitlefunc}
                  addtofunc={props.addtofunc}
                />
              ) : (
                <AdminEditCluster7Cols
                  key={props.index}
                  members={data}
                  index={props.index}
                  title={props.title}
                  deletefunc={props.deletefunc}
                  postfunc={props.postfunc}
                  dropfromfunc={props.dropfromfunc}
                  edittitlefunc={props.edittitlefunc}
                  addtofunc={props.addtofunc}
                />
              )
            ) : (
              <Container fluid className="clip-padding bwr-min-width">
                <div className="bwr-error">Nope</div>
              </Container>
            )
          }
        </Async.Resolved>
        <Async.Rejected>{(error) => error.message}</Async.Rejected>
      </Async>
    </Container>
  );

}

export default TopicsLoader;