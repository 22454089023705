/* eslint-disable no-unused-vars */
import React from "react";
import api from "../api";
import Async from "react-async";
import { Container } from "react-bootstrap";
import MediaQuery from "react-responsive";
import { Topic, SmallHeader } from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { AllTopicIDsProvider } from "../components/AllTopicIDsContext";


library.add(faSpinner);

function ShowSingleTopic(props) {

  //console.log(props);

  const params = new URLSearchParams(props.location.search);

  var id = params.get("id");
  var thumbnail = params.get("thumbnail");

  //console.log(thumbnail);

  if(!id) {
    id = "";
  }

  //console.log(id);

  const cluster = () => api.getCluster(id)
    .catch((err) => {
      console.log(err);
    });

  function resetHeight() {
    //document.getElementById('topicbody').style.marginTop = document.getElementById('smallheader').clientHeight;
    let smallheader = document.getElementsByClassName("smallheader");
    setTimeout(() => {  
        let margin = smallheader[0].firstChild.firstChild.clientHeight;
        //console.log(document.getElementsByClassName("topicbody"));
        document.getElementsByClassName("topicbody")[0].style.marginTop = margin - 145 + "px";
			}, 1000);
  }

  window.onload=resetHeight();

  return (
    <Container fluid className="clip-padding topics-padding-top">
      <Async promiseFn={cluster}>
        <Async.Loading>
          <center>
            <FontAwesomeIcon icon="spinner" pulse /> Loading
          </center>
        </Async.Loading>
        <Async.Resolved>
          {(data) =>
            data.data ? (
              <Container
                fluid
                className="clip-padding"
                style={{ position: "relative", top: "20px" }}
              >
                {thumbnail ? (
                  <div>
                    <div className="smallheader">
                      <SmallHeader
                        cluster={data.data.data}
                        thumbnail={thumbnail}
                      />
                    </div>
                    <MediaQuery query="(max-width: 768px)">
                      <div className="topicbody">
                        <Topic
                          cluster={data.data.data}
                          cols="3"
                          thumbnail={thumbnail}
                        />
                      </div>
                    </MediaQuery>
                    <MediaQuery query="(min-width: 769px)">
                      <div className="topicbody">
                        <Topic
                          cluster={data.data.data}
                          cols="7"
                          thumbnail={thumbnail}
                        />
                      </div>
                    </MediaQuery>
                  </div>
                ) : (
                  <AllTopicIDsProvider>
                    <div className="smallheader">
                      <SmallHeader
                        cluster={data.data.data}
                        thumbnail={thumbnail}
                      />
                    </div>
                    <MediaQuery query="(max-width: 768px)">
                      <div className="topicbody">
                        <Topic
                          cluster={data.data.data}
                          cols="3"
                          thumbnail={thumbnail}
                        />
                      </div>
                    </MediaQuery>
                    <MediaQuery query="(min-width: 769px)">
                      <div className="topicbody">
                        <Topic
                          cluster={data.data.data}
                          cols="7"
                          thumbnail={thumbnail}
                        />
                      </div>
                    </MediaQuery>
                  </AllTopicIDsProvider>
                )}
              </Container>
            ) : (
              <Container fluid className="clip-padding bwr-min-width">
                <div className="bwr-error">Nope</div>
              </Container>
            )
          }
        </Async.Resolved>
        <Async.Rejected>{(error) => error.message}</Async.Rejected>
      </Async>
    </Container>
  );

}

export default ShowSingleTopic;
