import React from "react";
import {
  Container,
  Row, Col, 
} from "react-bootstrap";
import {NewsCardFactual } from ".";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function NewsFactual6Cols(props) {

  function getBwrColor(bias) {
    let returnColor = "";
    switch(bias) {
      case 0:
      case 1: returnColor = "blue"; break;
      case 2:
      case 3:
      case 4: returnColor = "white"; break;
      case 5:
      case 6: returnColor = "red"; break;
      default: break;
    }
    return returnColor;
  }

  let bwrColor = "";

  const highItems1 = [];
  for (let i=0; i<props.items.length; i++) {
    bwrColor = getBwrColor(props.items[i].bias);
    if (((props.items[i].factual_score === 0) ||
        (props.items[i].factual_score === 1)) && 
        (i % 2 === 1)) {
      highItems1.push(<NewsCardFactual
        key={props.items[i].id}
        value={props.items[i]._id}
        bwrColor={bwrColor}
        factual="high"
        source={props.items[i].source}
        url={props.items[i].url}
        title={props.items[i].title}
        img={props.items[i].image}
        fblikes={props.items[i].fblikes}
        published={props.items[i].published}
        virality={props.items[i].virality}
        sortby={props.sortby}
      />)
    }
  }

  const highItems2 = [];
  for(let i = 0;i < props.items.length;i++) {
    bwrColor = getBwrColor(props.items[i].bias);
    if(((props.items[i].factual_score === 0) ||
      (props.items[i].factual_score === 1)) &&
      (i % 2 === 0)) {
      highItems2.push(<NewsCardFactual
        key={props.items[i].id}
        value={props.items[i]._id}
        bwrColor={bwrColor}
        factual="high"
        source={props.items[i].source}
        url={props.items[i].url}
        title={props.items[i].title}
        img={props.items[i].image}
        fblikes={props.items[i].fblikes}
        published={props.items[i].published}
        virality={props.items[i].virality}
        sortby={props.sortby}
      />)
    }
  }

  const mixedItems1 = [];
  for(let i = 0;i < props.items.length;i++) {
    bwrColor = getBwrColor(props.items[i].bias);
    if((props.items[i].factual_score === 2) &&
      (i % 2 === 1)) {
      mixedItems1.push(<NewsCardFactual
        key={props.items[i].id}
        value={props.items[i]._id}
        bwrColor={bwrColor}
        factual="mixed"
        source={props.items[i].source}
        url={props.items[i].url}
        title={props.items[i].title}
        img={props.items[i].image}
        fblikes={props.items[i].fblikes}
        published={props.items[i].published}
        virality={props.items[i].virality}
        sortby={props.sortby}
      />)
    }
  }

  const mixedItems2 = [];
  for(let i = 0;i < props.items.length;i++) {
    bwrColor = getBwrColor(props.items[i].bias);
    if((props.items[i].factual_score === 2) &&
      (i % 2 === 0)) {
      mixedItems2.push(<NewsCardFactual
        key={props.items[i].id}
        value={props.items[i]._id}
        bwrColor={bwrColor}
        factual="mixed"
        source={props.items[i].source}
        url={props.items[i].url}
        title={props.items[i].title}
        img={props.items[i].image}
        fblikes={props.items[i].fblikes}
        published={props.items[i].published}
        virality={props.items[i].virality}
        sortby={props.sortby}
      />)
    }
  }

  const lowItems1 = [];
  for(let i = 0;i < props.items.length;i++) {
    bwrColor = getBwrColor(props.items[i].bias);
    if (
       (props.items[i].factual_score === 3 ||
        props.items[i].factual_score === 4 ||
        props.items[i].factual_score === 5 ||
        props.items[i].factual_score === 6 ||
        props.items[i].factual_score === 9) &&
      i % 2 === 1
    ) {
      lowItems1.push(
        <NewsCardFactual
          key={props.items[i].id}
          value={props.items[i]._id}
          bwrColor={bwrColor}
          factual="low"
          source={props.items[i].source}
          url={props.items[i].url}
          title={props.items[i].title}
          img={props.items[i].image}
          fblikes={props.items[i].fblikes}
          published={props.items[i].published}
          virality={props.items[i].virality}
          sortby={props.sortby}
        />
      );
    }
  }

  const lowItems2 = [];
  for(let i = 0;i < props.items.length;i++) {
    bwrColor = getBwrColor(props.items[i].bias);
    if(((props.items[i].factual_score === 4) ||
      (props.items[i].factual_score === 5) ||
      (props.items[i].factual_score === 6) ||
      (props.items[i].factual_score === 9)) &&
      (i % 2 === 0)) {
      lowItems1.push(<NewsCardFactual
        key={props.items[i].id}
        value={props.items[i]._id}
        bwrColor={bwrColor}
        factual="low"
        source={props.items[i].source}
        url={props.items[i].url}
        title={props.items[i].title}
        img={props.items[i].image}
        fblikes={props.items[i].fblikes}
        published={props.items[i].published}
        virality={props.items[i].virality}
        sortby={props.sortby}
      />)
    }
  }
  
  //let toLog = "LOGGING: NEWS 3COLS";
  //console.log(blueItems);

  var columns = [highItems1, highItems2, mixedItems1, mixedItems2, lowItems1, lowItems2];

  function checkHasItems(item) {
    return item !== null;
  }
  
  const blankItems = columns.map((column) => 
    !column.some(checkHasItems) ? <div className='bwr-error'><p>No stories found {'\n'} with {">"}10k <FontAwesomeIcon icon='thumbs-up' size='sm' /></p></div> : null
  );

	return (
    <Container fluid className="bwr-min-width text-center">
      <Row className="light-side-padding">
        <Col className="clip-padding col-heading-high">high</Col>
        <Col className="col-spacer"></Col>
        <Col className="clip-padding col-heading-mixed">mixed</Col>
        <Col className="col-spacer"></Col>
        <Col className="clip-padding col-heading-low">low</Col>
      </Row>
      <Row className="light-side-padding">
        <Col className="light-margin news-card-separator-high"></Col>
        <Col className="col-spacer"></Col>
        <Col className="light-margin news-card-separator-mixed"></Col>
        <Col className="col-spacer"></Col>
        <Col className="light-margin news-card-separator-low"></Col>
      </Row>
      <Row className="light-side-padding">
        <Col className="clip-padding">{blankItems[0] ? blankItems[0] : highItems1}</Col>
        <Col className="clip-padding">{blankItems[1] ? blankItems[1] : highItems2}</Col>
        <Col className="col-spacer"></Col>
        <Col className="clip-padding">{blankItems[2] ? blankItems[2] : mixedItems1}</Col>
        <Col className="clip-padding">{blankItems[3] ? blankItems[3] : mixedItems2}</Col>
        <Col className="col-spacer"></Col>
        <Col className="clip-padding">{blankItems[4] ? blankItems[4] : lowItems1}</Col>
        <Col className="clip-padding">{blankItems[5] ? blankItems[5] : lowItems2}</Col>
        {/* <Col className="clip-padding">{blueItems}</Col>
        <Col className="clip-padding">{whiteItems}</Col>
        <Col className="clip-padding">{redItems}</Col> */}
      </Row>
    </Container>
    
  );
}

export default NewsFactual6Cols;