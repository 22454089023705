import React from "react";
//import api from "../api";
import { Container } from "react-bootstrap";
import { HeaderBar } from "../components";

function ShowAnalytics() {
  return (
    <Container fluid className="clip-padding header-padding-top">
      <HeaderBar page="analytics"/>
      <p>Here are some analytics</p>
    </Container>
  );
}

export default ShowAnalytics;
