import React, { useState } from "react";
import api from "../api";
import {
  Container,
  Row, Col
} from "react-bootstrap";
import { NewsCard } from ".";

function AdminMore3Cols(props) {

  //console.log("Loading More");

  const [moreStories, setMoreStories] = useState(props.moreStories);

  function hideStory(story) {
    console.log("Hiding story " + story);
    const result = api.hideStory({ id: story }).catch((err) => {
      console.log(err);
    });
    let newMoreStories = [...moreStories];
    for (let i = 0; i < newMoreStories.length; i++) {
      if (newMoreStories[i].id === story) {
        newMoreStories.splice(i,1);
      }
    }
    setMoreStories(newMoreStories);
    return result;
  }

  let existingMembers = [];
  for (let member of props.members) {
    existingMembers.push(member.id);
  }

  function newCluster(id) {
    console.log("Publishing new cluster with story id " + id);
    let newMoreStories = [...moreStories];
    for (let i = 0; i < newMoreStories.length; i++) {
      if (newMoreStories[i].id === id) {
        console.log("Publishing...");
        let toPublish = [];
        toPublish[0] = ">>>DRAFT: " + newMoreStories[i].title;
        toPublish[1] = newMoreStories[i].id;
        console.log(toPublish);
        // eslint-disable-next-line no-unused-vars
        const result = api.addCluster(toPublish).catch((err) => {
          console.log(err);
        });

        newMoreStories.splice(i, 1);
      }
    }
    setMoreStories(newMoreStories);
  }

  async function handleAddto(cluster, story, clustertitle) {
    // let newMoreStories = [...moreStories];
    // for (let i = 0; i < newMoreStories.length; i++) {
    //   if (newMoreStories[i].id === story) {
    //     console.log("Removing story id " + story);
    //     newMoreStories.splice(i, 1);
    //   }
    // }
    // setMoreStories(newMoreStories);
    let result = props.addtofunc(cluster, story, clustertitle);
    return result;
  }

  const [moreCollapsed, setMoreCollapsed] = useState(false);

  function dropNulls(arrayRef) {
    let array = [];
    // eslint-disable-next-line no-unused-vars
    let i = 0;
    for (let element of arrayRef) {
      if (element === null) {
        i++;
      } else array.push(element);
    }
    return array;
  }

  let mappedblueItems = moreStories.map((item) =>
    item.bias === 0 || item.bias === 1 ? (
      <NewsCard
        key={item.id}
        value={item._id}
        bwrColor="blue"
        source={item.source}
        url={item.url}
        title={item.title}
        img={item.image}
        fblikes={item.fblikes}
        published={item.published}
        virality={item.virality}
        type="admin-more"
        cluster={props.index}
        addtofunc={handleAddto}
        hidefunc={hideStory}
        newfunc={newCluster}
        clustertitle={props.title}
      />
    ) : null
  );

  let mappedwhiteItems = moreStories.map((item) =>
    item.bias === 2 || item.bias === 3 || item.bias === 4 ? (
      <NewsCard
        key={item.id}
        value={item._id}
        bwrColor="white"
        source={item.source}
        url={item.url}
        title={item.title}
        img={item.image}
        fblikes={item.fblikes}
        published={item.published}
        virality={item.virality}
        type="admin-more"
        cluster={props.index}
        addtofunc={handleAddto}
        hidefunc={hideStory}
        newfunc={newCluster}
        clustertitle={props.title}
      />
    ) : null
  );

  let mappedredItems = moreStories.map((item) =>
    item.bias === 5 || item.bias === 6 ? (
      <NewsCard
        key={item.id}
        value={item._id}
        bwrColor="red"
        source={item.source}
        url={item.url}
        title={item.title}
        img={item.image}
        fblikes={item.fblikes}
        published={item.published}
        virality={item.virality}
        type="admin-more"
        cluster={props.index}
        addtofunc={handleAddto}
        hidefunc={hideStory}
        newfunc={newCluster}
        clustertitle={props.title}
      />
    ) : null
  );

  let blueItems = dropNulls(mappedblueItems);
  let whiteItems = dropNulls(mappedwhiteItems);
  let redItems = dropNulls(mappedredItems);

  let firstRow = [blueItems[0], whiteItems[0], redItems[0]];
  //console.log(props);

  let moreCounts = [];
  if (blueItems.length > 1) {
    moreCounts[0] = blueItems.length - 1;
  }
  if (whiteItems.length > 1) {
    moreCounts[1] = whiteItems.length - 1;
  }
  if (redItems.length > 1) {
    moreCounts[2] = redItems.length - 1;
  }

  return (
    <Container fluid className="bwr-min-width clip-padding">
      {/* <Form>
        <Form.Group inline="true"> */}
      <Row className="medium-padding clip-margin">
        <Col className="news-card-separator-white"></Col>
      </Row>
      {!moreCollapsed ? (
        <Row
          className="medium-padding clip-margin"
          style={{ marginTop: "20px", marginBottom: "-5px" }}
        >
          <Col
            onClick={() => setMoreCollapsed(!moreCollapsed)}
            className="cluster-toggle"
          >
            Collapse Suggested Stories
          </Col>
        </Row>
      ) : null}
      {moreCollapsed ? (
        <div>
          <Row xs="3" sm="3" className="light-side-padding clip-margin">
            <Col className="clip-padding">{firstRow[0]}</Col>
            <Col className="clip-padding">{firstRow[1]}</Col>
            <Col className="clip-padding">{firstRow[2]}</Col>
          </Row>
          <Row xs="3" sm="3" className="light-side-padding clip-margin">
            {moreCounts[0] ? (
              <Col className="clip-padding">
                <div
                  onClick={() => setMoreCollapsed(!moreCollapsed)}
                  className="more-cluster-blue"
                >
                  +{moreCounts[0]} more
                </div>
              </Col>
            ) : (
              <Col className="clip-padding">
                <div className="more-cluster-transparent"></div>
              </Col>
            )}
            {moreCounts[1] ? (
              <Col className="clip-padding">
                <div
                  onClick={() => setMoreCollapsed(!moreCollapsed)}
                  className="more-cluster-white"
                >
                  +{moreCounts[1]} more
                </div>
              </Col>
            ) : (
              <Col className="clip-padding">
                <div className="more-cluster-transparent"></div>
              </Col>
            )}
            {moreCounts[2] ? (
              <Col className="clip-padding">
                <div
                  onClick={() => setMoreCollapsed(!moreCollapsed)}
                  className="more-cluster-red"
                >
                  +{moreCounts[2]} more
                </div>
              </Col>
            ) : (
              <Col className="clip-padding">
                <div className="more-cluster-transparent"></div>
              </Col>
            )}
          </Row>
        </div>
      ) : (
        <Row xs="3" sm="3" className="light-side-padding clip-margin">
          <Col className="clip-padding">{blueItems}</Col>
          <Col className="clip-padding">{whiteItems}</Col>
          <Col className="clip-padding">{redItems}</Col>
        </Row>
      )}
      {!moreCollapsed ? (
        <Row
          className="medium-padding clip-margin"
          style={{ position: "relative", top: "15px" }}
        >
          <Col
            onClick={() => setMoreCollapsed(!moreCollapsed)}
            className="cluster-toggle"
          >
            Collapse Suggested Stories
          </Col>
        </Row>
      ) : null}
      {/* </Form.Group>
      </Form> */}
    </Container>
  );
}

export default AdminMore3Cols;