import React, {useCallback, useContext} from "react";
import { withRouter, Redirect } from "react-router";
import app from "../base";
import { AuthContext } from "../auth";
import { Container, Form, Button } from "react-bootstrap";

const LoginPage = (props) => {

	let history = props.history;

	//console.log(props.location.state.target.target);

	const handleLogin = useCallback(
		async event => {
			event.preventDefault();
			const { email, password } = event.target.elements;
			try {
				await app
					.auth()
					.signInWithEmailAndPassword(email.value, password.value);
				history.push("/admin");
			} catch (err) {
				console.log(err);
			}
		},
		[history]
	);

	const { currentUser } = useContext(AuthContext);

	if (currentUser) {
		// return <Redirect to="/admin" />;
		return <Redirect to={props.location.state.target.target} />;
	}

	return (
		<Container>
			<Form onSubmit={handleLogin}>
				<Form.Control
                    name="email"
                    className="admin-cluster-namefield"
                    type="email"
                    placeholder="Email"
                />
				<Form.Control
                    name="password"
                    className="admin-cluster-namefield"
                    type="password"
                    placeholder="Password"
                />
				<Button type="submit" className="button-admin">
                    Log In
                </Button>
			</Form>
		</Container>
	);
}

export default withRouter(LoginPage);