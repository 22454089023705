import React from "react";
import {
  Container,
  Row, Col, 
} from "react-bootstrap";
import {NewsCardFactual } from ".";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function NewsFactual3Cols(props) {

  function getBwrColor(bias) {
    let returnColor = "";
    switch(bias) {
      case 0:
      case 1: returnColor = "blue"; break;
      case 2:
      case 3:
      case 4: returnColor = "white"; break;
      case 5:
      case 6: returnColor = "red"; break;
      default: break;
    }
    return returnColor;
  }

  let bwrColor = "";

  const highItems = [];
  for (let i=0; i<props.items.length; i++) {
    bwrColor = getBwrColor(props.items[i].bias);
    if (((props.items[i].factual_score === 0) ||
        (props.items[i].factual_score === 1))) {
      highItems.push(<NewsCardFactual
        key={props.items[i].id}
        value={props.items[i]._id}
        bwrColor={bwrColor}
        factual="high"
        source={props.items[i].source}
        url={props.items[i].url}
        title={props.items[i].title}
        img={props.items[i].image}
        fblikes={props.items[i].fblikes}
        published={props.items[i].published}
        virality={props.items[i].virality}
        sortby={props.sortby}
      />)
    }
  }

  const mixedItems = [];
  for(let i = 0;i < props.items.length;i++) {
    bwrColor = getBwrColor(props.items[i].bias);
    if(((props.items[i].factual_score === 2))) {
      mixedItems.push(<NewsCardFactual
        key={props.items[i].id}
        value={props.items[i]._id}
        bwrColor={bwrColor}
        factual="mixed"
        source={props.items[i].source}
        url={props.items[i].url}
        title={props.items[i].title}
        img={props.items[i].image}
        fblikes={props.items[i].fblikes}
        published={props.items[i].published}
        virality={props.items[i].virality}
        sortby={props.sortby}
      />)
    }
  }

  const lowItems = [];
  for(let i = 0;i < props.items.length;i++) {
    bwrColor = getBwrColor(props.items[i].bias);
    if (
      props.items[i].factual_score === 3 ||
      props.items[i].factual_score === 4 ||
      props.items[i].factual_score === 5 ||
      props.items[i].factual_score === 6 ||
      props.items[i].factual_score === 9 ||
      props.items[i].type === "questionable"
    ) {
      lowItems.push(
        <NewsCardFactual
          key={props.items[i].id}
          value={props.items[i]._id}
          bwrColor={bwrColor}
          factual="low"
          source={props.items[i].source}
          url={props.items[i].url}
          title={props.items[i].title}
          img={props.items[i].image}
          fblikes={props.items[i].fblikes}
          published={props.items[i].published}
          virality={props.items[i].virality}
          sortby={props.sortby}
        />
      );
    }
  }

  var columns = [highItems, mixedItems, lowItems];

  function checkHasItems(item) {
    return item !== null;
  }
  
  const blankItems = columns.map((column) => 
    !column.some(checkHasItems) ? <div className='bwr-error'><p>No stories found {'\n'} with {">"}10k <FontAwesomeIcon icon='thumbs-up' size='sm' /></p></div> : null
  );

	return (
    <Container fluid className="bwr-min-width text-center">
      <Row className="light-side-padding">
        <Col className="clip-padding col-heading-high">high</Col>
        <Col className="clip-padding col-heading-mixed">mixed</Col>
        <Col className="clip-padding col-heading-low">low</Col>
      </Row>
      <Row className="light-side-padding">
        <Col className="light-margin news-card-separator-high"></Col>
        <Col className="light-margin news-card-separator-mixed"></Col>
        <Col className="light-margin news-card-separator-low"></Col>
      </Row>
      <Row className="light-side-padding">
        <Col className="clip-padding">{blankItems[0] ? blankItems[0] : highItems}</Col>
        <Col className="clip-padding">{blankItems[1] ? blankItems[1] : mixedItems}</Col>
        <Col className="clip-padding">{blankItems[2] ? blankItems[2] : lowItems}</Col>
      </Row>
    </Container>
  );
}

export default NewsFactual3Cols;