import React, {useState} from "react";
import api from "../api";
import { Container, Row, Col, Form } from "react-bootstrap";
import { AdminTopicsLoader } from ".";

function AdminEditClusters(props) {

  const [clusters, setClusters] = useState(props.items);
  // const [dummy, setDummy] = useState("Dummy");

  const [thisCluster, setThisCluster] = useState(props.items[0]);
  
  function editClusterTitle(cluster, title) {
    console.log("Editing cluster " + cluster + " title to " + title);

    const result = api.editClusterTitle({
      title: title,
      cluster: cluster,
    }).catch((err) => {
      console.log(err);
    });

    return result;
  }

  function deleteCluster(index) {
    //console.log(action);
    console.log("Deleting cluster " + index);

    let newClusters = [...clusters];
    for (var i = 0; i < newClusters.length; i++) {
      if (newClusters[i].id === index) {
        newClusters.splice(i, 1);
      }
    }
    setClusters(newClusters);

    const result = api
      .deleteCluster({
        id: index
      })
      .catch((err) => {
        console.log(err);
      });

    return result;
  }

  function postCluster(index) {
    //console.log(action);
    console.log("Posting cluster " + index);

    api.postCluster({
        id: index
      }).catch((err) => {
          console.log(err);
        });

  }
  

  function dropfromCluster(cluster, index) {
    console.log("Dropping story " + index + " from cluster " + cluster);

    let newClusters = [...clusters];
    for (var i = 0; i < newClusters.length; i++) {
      //console.log(newClusters[i].id);
      if (newClusters[i].id === cluster) {
        //console.log("Found cluster");
        for (var j = 0; j < newClusters[i].memberids.length; j++) {
          //console.log(newClusters[i].members[j].id);
          if (newClusters[i].memberids[j] === index) {
            console.log(
              "Found at position " + j + " of " + newClusters[i].memberids.length
            );
            newClusters[i].memberids.splice(j, 1);
            //console.log("New size of cluster " + cluster + ": " + newClusters[i].members.length)
          }
        }
      }
    }
    setClusters(newClusters);

    const result = api
      .dropFromCluster({
        cluster: cluster,
        id: index
      })
      .catch((err) => {
        console.log(err);
      });

    return result;
  }

  async function addToCluster(cluster, index, clustertitle) {
    console.log("Adding story " + index + " to cluster " + cluster);

    let newClusters = [...clusters];
    for (var i = 0; i < newClusters.length; i++) {
      if (newClusters[i].id === cluster) {
        console.log("Adding...");
        // const result = await api.getStory(index)
        // .catch((err) => {
        //   console.log(err);
        // });

        // let newsItem = result.data.data;
        // console.log(newsItem);
        // console.log(newClusters[i]);

        newClusters[i].memberids.push(index);

        //console.log(newClusters[i]);

        //let toPublish = [...newClusters[i].members];
        let toPublish = [clustertitle, index]
        //console.log(toPublish);
        // eslint-disable-next-line no-unused-vars
        const final = await api.addToCluster(toPublish).catch((err) => {
          console.log(err);
        });

      }
    }
    setClusters(newClusters);

    return index;
  }

  function handleOnChange(title) {
    console.log("Changed to " + title);
    for (var cluster of clusters) {
      if (cluster.title === title) {
        setThisCluster(cluster);
      }
    }
  }

  return (
    <Container fluid className="clip-padding bwr-min-width text-center">
      <Row className="clip-margin" style={{ backgroundColor: "#fff7ae" }}>
        <Col className="cluster-header button-admin-wrapper">
          <Container fluid className="clip-padding">
            <Form.Control
              as="select"
              className="admin-cluster-selectfield"
              value={thisCluster.title}
              type="search"
              placeholder="Select cluster..."
              onChange={(event) => {
                handleOnChange(event.target.value);
              }}
              custom
            >
              <option key="0">Select cluster...</option>
              {clusters.map((cluster) => (
                <option key={cluster._id}>{cluster.title}</option>
              ))}
            </Form.Control>
          </Container>
        </Col>
      </Row>
      {/* {dummy} */}
      <AdminTopicsLoader
        key={thisCluster.id}
        cols={props.cols}
        memberids={thisCluster.memberids}
        index={thisCluster.id}
        title={thisCluster.title}
        deletefunc={deleteCluster}
        postfunc={postCluster}
        dropfromfunc={dropfromCluster}
        edittitlefunc={editClusterTitle}
        addtofunc={addToCluster}
      />
      {/* {props.cols === "3" ? (
        <AdminEditCluster3Cols
          key={thisCluster.id}
          members={thisCluster.members}
          index={thisCluster.id}
          title={thisCluster.title}
          deletefunc={deleteCluster}
          postfunc={postCluster}
          dropfromfunc={dropfromCluster}
          edittitlefunc={editClusterTitle}
          addtofunc={addToCluster}
        />
      ) : (
        <AdminEditCluster7Cols
          key={thisCluster.id}
          members={thisCluster.members}
          index={thisCluster.id}
          title={thisCluster.title}
          deletefunc={deleteCluster}
          postfunc={postCluster}
          dropfromfunc={dropfromCluster}
          edittitlefunc={editClusterTitle}
          addtofunc={addToCluster}
        />
      )} */}
    </Container>
  );

}

export default AdminEditClusters;