import React from "react";
import {
  Container,
  Row, Col,
} from "react-bootstrap";
import { NewsCard } from "./";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function News7Columns(props) {

  const bluestItems = props.items.map((item) =>
    item.bias === 0 ? (
      <NewsCard
        key={item.id}
        value={item._id}
        bwrColor="blue"
        source={item.source}
        url={item.url}
        sourcetype={item.type}
        title={item.title}
        img={item.image}
        fblikes={item.fblikes}
        published={item.published}
        virality={item.virality}
        sortby={props.sortby}
      />
    ) : null
  );

  const blueItems = props.items.map((item) =>
    item.bias === 1 ? (
      <NewsCard
        key={item.id}
        value={item._id}
        bwrColor="blue"
        source={item.source}
        url={item.url}
        sourcetype={item.type}
        title={item.title}
        img={item.image}
        fblikes={item.fblikes}
        published={item.published}
        virality={item.virality}
        sortby={props.sortby}
      />
    ) : null
  );

  const bluishItems = props.items.map((item) =>
    item.bias === 2 ? (
      <NewsCard
        key={item.id}
        value={item._id}
        bwrColor="white"
        source={item.source}
        url={item.url}
        sourcetype={item.type}
        title={item.title}
        img={item.image}
        fblikes={item.fblikes}
        published={item.published}
        virality={item.virality}
        sortby={props.sortby}
      />
    ) : null
  );

  const whiteItems = props.items.map((item) =>
    item.bias === 3 ? (
      <NewsCard
        key={item.id}
        value={item._id}
        bwrColor="white"
        source={item.source}
        url={item.url}
        sourcetype={item.type}
        title={item.title}
        img={item.image}
        fblikes={item.fblikes}
        published={item.published}
        virality={item.virality}
        sortby={props.sortby}
      />
    ) : null
  );

  const reddishItems = props.items.map((item) =>
    item.bias === 4 ? (
      <NewsCard
        key={item.id}
        value={item._id}
        bwrColor="white"
        source={item.source}
        url={item.url}
        sourcetype={item.type}
        title={item.title}
        img={item.image}
        fblikes={item.fblikes}
        published={item.published}
        virality={item.virality}
        sortby={props.sortby}
      />
    ) : null
  );

  const redItems = props.items.map((item) =>
    item.bias === 5 ? (
      <NewsCard
        key={item.id}
        value={item._id}
        bwrColor="red"
        source={item.source}
        url={item.url}
        sourcetype={item.type}
        title={item.title}
        img={item.image}
        fblikes={item.fblikes}
        published={item.published}
        virality={item.virality}
        sortby={props.sortby}
      />
    ) : null
  );

  const reddestItems = props.items.map((item) =>
    item.bias === 6 ? (
      <NewsCard
        key={item.id}
        value={item._id}
        bwrColor="red"
        source={item.source}
        url={item.url}
        sourcetype={item.type}
        title={item.title}
        img={item.image}
        fblikes={item.fblikes}
        published={item.published}
        virality={item.virality}
        sortby={props.sortby}
      />
    ) : null
  );

  //let toLog = "LOGGING: NEWS 7COLS";

  var columns = [ bluestItems, 
                  blueItems,
                  bluishItems,
                  whiteItems,
                  reddishItems,
                  redItems,
                  reddestItems ];

  function checkHasItems(item) {
    return item !== null;
  }

  const blankItems = columns.map((column) =>
    !column.some(checkHasItems) ? <div className='bwr-error'><p>No stories found {'\n'} with {">"}10k <FontAwesomeIcon icon='thumbs-up' size='sm' /></p></div> : null
  );

	return (
    <Container fluid className="bwr-min-width text-center">
      <Row md="7" lg="7" xl="7" className="light-side-padding">
        <Col className="clip-padding col-heading-blue">bluest</Col>
        <Col className="clip-padding col-heading-blue">blue</Col>
        <Col className="clip-padding col-heading-white">bluish</Col>
        <Col className="clip-padding col-heading-white">white</Col>
        <Col className="clip-padding col-heading-white">reddish</Col>
        <Col className="clip-padding col-heading-red">red</Col>
        <Col className="clip-padding col-heading-red">reddest</Col>
      </Row>
      <Row md="7" lg="7" xl="7" className="light-side-padding">
        <Col className="clip-padding">{blankItems[0] ? blankItems[0] : bluestItems}</Col>
        <Col className="clip-padding">{blankItems[1] ? blankItems[1] : blueItems}</Col>
        <Col className="clip-padding">{blankItems[2] ? blankItems[2] : bluishItems}</Col>
        <Col className="clip-padding">{blankItems[3] ? blankItems[3] : whiteItems}</Col>
        <Col className="clip-padding">{blankItems[4] ? blankItems[4] : reddishItems}</Col>
        <Col className="clip-padding">{blankItems[5] ? blankItems[5] : redItems}</Col>
        <Col className="clip-padding">{blankItems[6] ? blankItems[6] : reddestItems}</Col>
        {/* <Col className="clip-padding">{bluestItems}</Col>
        <Col className="clip-padding">{blueItems}</Col>
        <Col className="clip-padding">{bluishItems}</Col>
        <Col className="clip-padding">{whiteItems}</Col>
        <Col className="clip-padding">{reddishItems}</Col>
        <Col className="clip-padding">{redItems}</Col>
        <Col className="clip-padding">{reddestItems}</Col> */}
      </Row>
      {/* <BWRLogger event={toLog} /> */}
    </Container>
  );
}

export default News7Columns;