import React from "react";
import api from "../api";
import { Container, Card, Accordion, Jumbotron } from "react-bootstrap";
import {HeaderBar,SourceTable3Cols,SourceTable7Cols } from "../components/";
import Async from "react-async";
import MediaQuery from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

library.add(faSpinner);

function ShowHowItWorks() {

  const items = () => api.showSources();

  return (
    <Container fluid className="clip-padding header-padding-top">
      <Container fluid className="clip-padding bwr-bgcolor-dark">
        <HeaderBar page="howitworks" />
        <Jumbotron
          fluid
          className="hiw-header bwr-max-width-center bwr-bgcolor-dark"
        >
          <h2>How it works</h2>
          <p></p>
          <p>
            <a href="https://bluewhitered.org">bluewhitered.org</a> wants to
            help you make up your own mind about the News by providing a broad
            spectrum of perspectives completely free of algorithmic targeting.
          </p>
          <p>Why does that matter and how does it work?</p>
        </Jumbotron>
      </Container>
      <Container fluid className="clip-padding">
        <Accordion defaultActiveKey="0" className="bwr-max-width-center">
          <Card className="hiw-topic">
            <Accordion.Toggle
              className="bwr-bgcolor-blank"
              as={Card.Header}
              eventKey="0"
            >
              Why bluewhitered.org?
            </Accordion.Toggle>
            <Accordion.Collapse className="hiw-text" eventKey="0">
              <Card.Body>
                <p>
                  The News, wherever you read it, can be scary and confusing. It
                  can feel like you're not getting the{" "}
                  <a
                    href="https://www.journalism.org/2019/10/02/americans-are-wary-of-the-role-social-media-sites-play-in-delivering-the-news/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    whole picture
                  </a>
                  , or being sold a story from a{" "}
                  <a
                    href="https://www.politico.com/story/2019/08/20/facebook-audit-conservative-bias-amid-criticism-1469287"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    specific
                  </a>{" "}
                  point of view. And that is because,{" "}
                  <a
                    href="https://www.washingtonpost.com/news/the-intersect/wp/2015/12/16/you-might-think-trump-is-bad-but-unfriending-his-supporters-on-facebook-is-worse/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    increasingly
                  </a>
                  ,{" "}
                  <a
                    href="https://people.mpi-sws.org/~achakrab/papers/ribiero_bias_monitor_ICWSM18.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    that
                  </a>{" "}
                  <a
                    href="https://mashable.com/article/facebook-right-wing-social-network/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    is
                  </a>{" "}
                  <a
                    href="https://www.washingtonpost.com/technology/2019/08/20/facebook-makes-small-tweaks-following-anti-conservative-bias-report-theyre-unlikely-make-issue-go-away/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    the
                  </a>{" "}
                  <a
                    href="https://www.wsj.com/articles/google-and-facebook-worsen-media-bias-11549829040"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    case
                  </a>
                  .
                </p>
                <p>
                  A{" "}
                  <a
                    href="https://library.fvtc.edu/News/BiasCheck"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    polarized
                  </a>{" "}
                  media landscape is nothing new, but with ~55% of Americans
                  getting their{" "}
                  <a
                    href="https://www.journalism.org/2019/10/02/americans-are-wary-of-the-role-social-media-sites-play-in-delivering-the-news/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    news on Facebook
                  </a>{" "}
                  (close to the percentage of eligible Americans that{" "}
                  <a
                    href="https://guides.libraries.psu.edu/post-election-2016/voter-turnout"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    typically vote
                  </a>
                  ), it has never been easier to{" "}
                  <a
                    href="https://www.wired.com/story/russian-facebook-ads-targeted-us-voters-before-2016-election/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    target
                  </a>{" "}
                  specific populations with messaging designed to make them
                  think - and eventually{" "}
                  <a
                    href="https://intelligence.house.gov/social-media-content/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    act
                  </a>{" "}
                  - a certain way.
                </p>
                <p>
                  <a
                    href="http://bluewhitered.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    bluewhitered.org
                  </a>{" "}
                  (BWR) exists to provide a fuller picture - to avoid the
                  targeting and filter bubbles that most News platforms are
                  built upon. If you want to get an idea of what everyone is
                  reading - not just your circle of friends and relatives - it's
                  for you.
                </p>
                <p>
                  Nothing on this site is personalized. BWR shows every popular
                  article being read from the furthest left to the farthest
                  right, with as much data and unbiased context as can be
                  provided.
                </p>
                <p>
                  I hope you use it to make up your own mind about the world we
                  all live in.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="hiw-topic">
            <Accordion.Toggle
              className="bwr-bgcolor-blank"
              as={Card.Header}
              eventKey="1"
            >
              How does it work?
            </Accordion.Toggle>
            <Accordion.Collapse className="hiw-text" eventKey="1">
              <Card.Body>
                <p>
                  Every hour, BWR pulls in News stories that have more than
                  10,000 Likes on Facebook (our threshold for a story being
                  "popular") and based on their source, classifies them into
                  Blues, Whites and Reds.
                </p>
                <p>
                  This data comes from a service called{" "}
                  <a
                    href="https://webhose.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Webhose.io
                  </a>{" "}
                  via their API. They have a crawler that scrapes data from
                  thousands of News sites every day and tracks all kinds of
                  useful metadata, including when it was published, how many
                  Facebook Likes it has and so on. BWR only asks for politically
                  related news, but sometimes some other stuff sneaks in there
                  too.
                </p>
                <p>
                  In order to classify the stories, BWR uses source ratings from media rating organizations{" "}(
                    <a
                    href="https://www.allsides.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    AllSides.com
                    </a>{" "}and{" "} 
                    <a
                      href="https://mediabiasfactcheck.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MediaBiasFactCheck.com
                    </a>), which are independently funded and not associated with major political parties or publication houses.
                  (They have descriptions of their methodology and funding - check{" "}
                  <a
                    href="https://www.allsides.com/about"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    them
                  </a>{" "}
                  <a
                    href="https://mediabiasfactcheck.com/funding/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    out
                  </a>
                  ). 
                  In all, BWR has classifications for
                  over 300 separate News sources representing every part of the
                  political spectrum. Blues lean more to the US political Left,
                  Reds to the Right, and Whites are more Centrist.
                </p>
                <p>
                  On a large screen, BWR allows you to sort, filter and view
                  those stories by when they were published, how popular they
                  are and how "viral" they have become since publishing.
                  Popularity is measured in Facebook Likes accumulated since the
                  story was published. "Virality" is defined as Facebook Likes
                  accumulated divided by the number of hours since the story was
                  published - it is intended to be a measure of how fast the
                  story became popular.
                </p>
                <p>
                  On a phone screen, you see three columns - Blues, Whites and
                  Reds. On a larger screen like a tablet or computer you see
                  seven columns - Bluest, Blue, Bluish, White, Reddish, Red and
                  Reddest - for more granularity. These correspond as below:
                </p>
                <center>
                  <img
                    src="images/colors.png"
                    width="100%"
                    alt="colors classification"
                    className="hiw-image"
                  ></img>
                </center>
                <p>
                  The News by Topic page consists of the same news articles as on
                  the main page, but organized by topic cluster and displayed in
                  the same spectrum of biases. You can click on a topic and see
                  all the articles associated with it from the time BWR started
                  collecting content. The process of matching articles to topics
                  is about 90% automated and 10% manual cleanup. Part of that
                  10% is the titling of each topic itself - though I'd like to
                  automate this too in the future. It's a particularly tricky
                  task to title each topic with as neutral language as possible.
                </p>
                <p>
                  On any page you can tap on a story and read a preview of it,
                  or read the whole thing on the original News site. I track
                  traffic and click-through to see how people use the feature,
                  but I take no affiliate fees of any kind from sites to which I
                  drive traffic.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="hiw-topic">
            <Accordion.Toggle
              className="bwr-bgcolor-blank"
              as={Card.Header}
              eventKey="2"
            >
              Who are you?
            </Accordion.Toggle>
            <Accordion.Collapse className="hiw-text" eventKey="2">
              <Card.Body>
                <p>I'm a creative technologist living in New York City.</p>
                <p>
                  I like hearing all sides of a story before deciding what to
                  believe.
                </p>
                <p>
                  Follow me on Instagram (
                  <a
                    href="https://www.instagram.com/akoithra/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    @akoithra
                  </a>
                  ) to see updates on BWR or if you like pictures of dogs.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="hiw-topic">
            <Accordion.Toggle
              className="bwr-bgcolor-blank"
              as={Card.Header}
              eventKey="3"
            >
              Who funds bluewhitered.org?
            </Accordion.Toggle>
            <Accordion.Collapse className="hiw-text" eventKey="3">
              <Card.Body>
                <p>
                  Just me. I designed and built it and I pay for the server and
                  API costs myself. It's not expensive.
                </p>
                <p>
                  I will never put advertising on BWR, or take fees for driving
                  traffic.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="hiw-topic">
            <Accordion.Toggle
              className="bwr-bgcolor-blank"
              as={Card.Header}
              eventKey="4"
            >
              Which News sources fall into which category?
            </Accordion.Toggle>
            <Accordion.Collapse className="hiw-text" eventKey="4">
              <Card.Body>
                <Async promiseFn={items}>
                  <Async.Loading>
                    <center>
                      <FontAwesomeIcon icon="spinner" pulse /> Loading
                    </center>
                  </Async.Loading>
                  <Async.Resolved>
                    {(data) => (
                      <Container fluid className="clip-padding">
                        <p>
                          Repeated sources in the lists below mean that multiple
                          URLs are attributed to that source.
                        </p>
                        <MediaQuery query="(max-width: 768px)">
                          <SourceTable3Cols items={data.data.data} />
                        </MediaQuery>
                        <MediaQuery query="(min-width: 769px)">
                          <SourceTable7Cols items={data.data.data} />
                        </MediaQuery>
                        <p></p>
                        <p>
                          Note that while a source may appear in this list,
                          some of them, like the New York Times for example, block access to
                          the Webhose crawler and so articles from those sources will
                          not appear in the BWR feed. The NYT has its own custom API
                          and that is why they block access, but I have not gone down
                          the road of custom connections to individual sources, and am
                          unlikely to do so. I've also blocked content from sources like TMZ
                          and People Magazine, whose content tends to be very celebrity-focused
                          and not very interesting for a news bias analysis.
                        </p>
                      </Container>
                    )}
                  </Async.Resolved>
                  <Async.Rejected>{(error) => error.message}</Async.Rejected>
                </Async>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="hiw-topic">
            <Accordion.Toggle
              className="bwr-bgcolor-blank"
              as={Card.Header}
              eventKey="5"
            >
              I don't agree with your classifications.
            </Accordion.Toggle>
            <Accordion.Collapse className="hiw-text" eventKey="5">
              <Card.Body>
                <p>
                  Good to know. As you might imagine, almost no one does, not
                  completely.
                </p>
                <p>
                  <a
                    href="mailto:hello@bluewhitered.org?subject=BWR%20Feedback%20on%20News%20Source%20classifications"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Email
                  </a>{" "}
                  me with suggestions and let's talk. Please be nice.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="hiw-topic">
            <Accordion.Toggle
              className="bwr-bgcolor-blank"
              as={Card.Header}
              eventKey="6"
            >
              The content is pretty US-focused.
            </Accordion.Toggle>
            <Accordion.Collapse className="hiw-text" eventKey="6">
              <Card.Body>
                <p>
                  That's intentional for now - BWR mainly searches US-based
                  media for US-focused topics. Several international
                  publications write about the US or related topics and that
                  stuff finds its way into the feed too. I'd like to eventually
                  expand the scope and offer all kinds of filtering. Watch this
                  space.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="hiw-topic">
            <Accordion.Toggle
              className="bwr-bgcolor-blank"
              as={Card.Header}
              eventKey="7"
            >
              Is this all it does?
            </Accordion.Toggle>
            <Accordion.Collapse className="hiw-text" eventKey="7">
              <Card.Body>
                <p>
                  Right now, yes. I'm working on a bunch of new features to be
                  released soon. Some of these include:
                </p>
                <ul>
                  <li>
                    Stories grouped by topic (harder to do when everything is
                    about COVID-19)
                  </li>
                  <li>
                    Detailed analytics powered by 2 years of archival News data
                  </li>
                  <li>
                    Dedicated social feeds auto-populated with Blue, White and
                    Red stories
                  </li>
                  <li>
                    An AI/ML engine that improves the classification methodology
                  </li>
                  <li>Put it all on the Blockchain (Just kidding. I think.)</li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Container>
      <Container fluid className="clip-padding bwr-bgcolor-neutral">
        <Jumbotron
          fluid
          className="hiw-footer bwr-max-width-center bwr-bgcolor-neutral"
        >
          <p>
            I'd love to hear what you think, or of any ideas you have for{" "}
            <a
              href="http://bluewhitered.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              bluewhitered.org
            </a>
            .
          </p>
          <p>
            <a
              href="mailto:hello@bluewhitered.org?subject=BWR%20Feedback"
              target="_blank"
              rel="noopener noreferrer"
            >
              Email
            </a>{" "}
            me and let's talk.
          </p>
        </Jumbotron>
      </Container>
    </Container>
  );
}

export default ShowHowItWorks;
