import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./auth";

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
	const {currentUser} = useContext(AuthContext);

	let target = rest.path;
	//console.log(target);
	
	return (
    <Route
      {...rest}
      render={(routeProps) =>
        !!currentUser ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { target: {target} },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;