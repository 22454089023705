/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NewsModal } from "./";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

library.add(faExclamationTriangle);

function custom_number_format(n) {
  var n_format=0;

  if (n < 1000) {
      // Anything less than a thousand
      n_format = n;
  } else if (n < 1000000) {
      // Anything less than a million
      n_format = (n / 1000).toFixed(1) + "K";
  } else if (n < 1000000000) {
      // Anything less than a billion
      n_format = (n / 1000000).toFixed(1) + "M";
  } else {
      // At least a billion
      n_format = (n / 1000000000).toFixed(1) + "B";
  }

  return n_format;
}

function timeDiffCalc(dateFuture, dateNow) {
  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;
  // console.log("calculated days", days);

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;
  // console.log("calculated hours", hours);

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;
  // console.log("minutes", minutes);

  let difference = "";
  if (days > 0) {
    difference += days === 1 ? `${days} day ` : `${days} days `;
  } else if (hours > 0) {
    difference +=
      hours === 0 || hours === 1 ? `${hours} hr ` : `${hours} hrs `;
  } else if (minutes > 0) {
    difference +=
      minutes === 0 || hours === 1
        ? `${minutes} min`
        : `${minutes} mins`;
  }

  return difference;
}

function publishedFormat(date) {
  var formattedDate = "";

  var pubDate = new Date(date);
  var nowDate = new Date();

  formattedDate = timeDiffCalc(nowDate, pubDate) + " ago"

  return formattedDate;
}

// async function getSourceInfo(sourcename) {
//   //console.log("Checking " + sourcename)
//   const sourceInfo = await api.getSource(sourcename).catch((err) => {
//     console.log(err);
//   });
//   //console.log(typeof(sourceInfo.data.data.type));
//   return sourceInfo.data.data;
// }

async function matchTitle(titleNouns, existing) {
  
  // const startTime = performance.now();
  

  console.log(titleNouns);

  let allTopics = [];
  for (let i = 0; i < existing.length; i++) {
    allTopics[i] = {};
    allTopics[i].id = existing[i].id;
    allTopics[i].nouns = [...existing[i].nouns];
  }

  for (let j = 0; j < allTopics.length; j++) {
    //console.log("Checking " + j + " " + allTitles[j].id);
    allTopics[j].score = 0;
    for (var eachNoun of titleNouns) {
      if (allTopics[j].nouns!==undefined) {
        // eslint-disable-next-line no-loop-func
        let position = allTopics[j].nouns.find(s => s.value === eachNoun);
        if (position !== undefined) {
          allTopics[j].score += position.relevance;
        }
      }
    }
  }

  function compare(a, b) {
    //console.log("comparing " + a.score + " & " + b.score);
    if (a.score > b.score) return -1;
    if (b.score > a.score) return 1;

    return 0;
  }

  allTopics.sort(compare);

  // console.log(allTopics[0]);
  // console.log(allTopics[1]);
  // console.log(allTopics[2]);

  let returnCluster = {};

  for (let each of existing) {
    if (each.id === allTopics[0].id) {
      returnCluster = each;
    }
  }

  // const duration = performance.now() - startTime;
  // console.log(`Topic matching took ${duration}ms`);
  
  return returnCluster;
}

function NewsCard(props) {

    //console.log(props);

    const [showModal, setShowModal] = useState(false);
    const [addName, setAddName] = useState("");
    const [first, setFirst] = useState(true);
    // const [questionable, setQuestionable] = useState(false);
    const [sourceinfo, setSourceInfo] = useState("");

    function compareTitle(a, b) {
      if (a.title > b.title) return 1;
      if (b.title > a.title) return -1;

      return 0;
    }

    // getSourceInfo(props.source).then((result) => {
    //   if (result.type == "questionable") {
    //     setQuestionable(true);
    //     setSourceInfo(result.info_url);
    //   }
    // });

    let alphaClusters = [];
    if (props.existing) {
      alphaClusters = [...props.existing];
      alphaClusters.sort(compareTitle);
    }

    function cardClick() {
      setShowModal(!showModal);
    }

    //RUN NOUN MATCHING FOR CARD
    if (props.type == "admin-rest") {
      // loadExistingNouns(props.existing).then((existing) => {
      //   console.log("newscard");
        matchTitle(props.nouns, props.existing).then((topic) => {
          //console.log(topic)
          if (first) {
            setAddName(topic.title);
            setFirst(false);
          }
        });
      // })
    }

    let max = 200;
    if (props.thumbnail) {
      max = 150;
    }

    let title = "";
    if (props.title.length > max) {
      title = props.title.substr(0, max);
    } else title = props.title;

 

    // let height = 0;
    // let avgCharWidth = 10;
    // let lineHeight = 20;

    return props.type == "admin" ? (
      // Admin NewsCard for draft cluster stories
      <Card className="newscard">
        <Card.Body className="news-card-all">
          <Card.Title className="news-card-source">{props.source}</Card.Title>
          <Card.Text
            className={"news-card-separator-" + props.bwrColor}
          ></Card.Text>
          <Card.Text className="news-card-title">{title}</Card.Text>
        </Card.Body>
        <Card.Footer className="news-card-footer">
          {publishedFormat(props.published)}
        </Card.Footer>
        <Card.Footer className="admin-card-footer">
          <Form.Group
            inline="true"
            controlId="Admin.DropStory"
            className="news-configurator-table"
          >
            <Button
              type="button"
              className="button-admin"
              onClick={() => props.dropfunc(props.cluster, props.value)}
            >
              Drop
            </Button>
            <Button
              type="button"
              className="button-admin"
              onClick={() => props.hidefunc(props.cluster, props.value)}
            >
              Hide
            </Button>
          </Form.Group>
        </Card.Footer>
        <NewsModal show={showModal} item={props} />
      </Card>
    ) : props.type == "admin-existing" ? (
      // Admin NewsCard for stories that are already in a cluster
      <Card className="newscard">
        <Card.Body className="news-card-all">
          <Card.Title className="news-card-source">{props.source}</Card.Title>
          <Card.Text
            className={"news-card-separator-" + props.bwrColor}
          ></Card.Text>
          <Card.Text className="news-card-title">{title}</Card.Text>
        </Card.Body>
        <Card.Footer className="news-card-footer">
          {publishedFormat(props.published)}
        </Card.Footer>
        <Card.Footer className="admin-card-footer">
          <Form.Group
            inline="true"
            controlId="Admin.DeleteStory"
            className="news-configurator-table"
          >
            <Button
              type="button"
              className="button-admin"
              onClick={() => props.dropfromfunc(props.cluster, props.value)}
            >
              Delete
            </Button>
          </Form.Group>
        </Card.Footer>
        <NewsModal show={showModal} item={props} />
      </Card>
    ) : props.type == "admin-more" ? (
      // Admin NewsCard for MORE component as part of EXISTING page - designed for suggested items
      <Card className="newscard">
        <Card.Body
          className="news-card-all"
          style={{ backgroundColor: "#fff7ae" }}
        >
          <Card.Title className="news-card-source">{props.source}</Card.Title>
          <Card.Text
            className={"news-card-separator-" + props.bwrColor}
          ></Card.Text>
          <Card.Text className="news-card-title">{title}</Card.Text>
        </Card.Body>
        <Card.Footer
          className="news-card-footer"
          style={{ backgroundColor: "#fff7ae" }}
        >
          {publishedFormat(props.published)}
        </Card.Footer>
        <Card.Footer className="admin-card-footer">
          <Form.Group
            inline="true"
            controlId="Admin.AddStory"
            className="news-configurator-table"
          >
            <Button
              type="button"
              className="button-admin"
              onClick={() =>
                props.addtofunc(props.cluster, props.value, props.clustertitle)
              }
            >
              Add
            </Button>
            <Button
              type="button"
              className="button-admin"
              onClick={() => props.hidefunc(props.value)}
            >
              Hide
            </Button>
            <Button
              type="button"
              className="button-admin"
              onClick={() => props.newfunc(props.value)}
            >
              New
            </Button>
          </Form.Group>
        </Card.Footer>
        <NewsModal show={showModal} item={props} />
      </Card>
    ) : props.type == "admin-rest" ? (
      // Admin NewsCard for REST page - designed for straggler items
      <Card className="newscard">
        <Card.Body
          className="news-card-all"
          style={{ backgroundColor: "#fff7ae" }}
        >
          <Card.Title className="news-card-source">{props.source}</Card.Title>
          <Card.Text
            className={"news-card-separator-" + props.bwrColor}
          ></Card.Text>
          <Card.Text className="news-card-title">{title}</Card.Text>
        </Card.Body>
        <Card.Footer
          className="news-card-footer"
          style={{ backgroundColor: "#fff7ae" }}
        >
          {publishedFormat(props.published)}
        </Card.Footer>
        <Card.Footer className="admin-card-footer">
          <Form.Group
            inline="true"
            controlId="Admin.AddStory"
            className="news-configurator-table"
          >
            <Form.Control
              as="select"
              className="admin-cluster-namefield-small"
              value={addName}
              type="search"
              placeholder="Select cluster..."
              onChange={(event) => {
                setAddName(event.target.value);
              }}
              custom
            >
              <option key="0">Select cluster...</option>
              {alphaClusters.map((cluster) => (
                <option key={cluster._id}>{cluster.title}</option>
              ))}
            </Form.Control>
            <Button
              type="button"
              className="button-admin"
              onClick={() => props.addtofunc(props.value, addName)}
            >
              Add To
            </Button>
            <Button
              type="button"
              className="button-admin"
              onClick={() => props.hidefunc(props.value)}
            >
              Hide
            </Button>
            <Button
              type="button"
              className="button-admin"
              onClick={() => props.newfunc(props.value)}
            >
              New
            </Button>
          </Form.Group>
        </Card.Footer>
        <NewsModal show={showModal} item={props} />
      </Card>
    ) : props.type == "topics" ? (
      // NewsCard for TOPICS page
      <Card className={"newscard-" + props.bwrColor} onClick={() => cardClick()}>
        <Card.Img
          variant="top"
          className="news-card-img-fixed"
          src={props.img}
          onError={(event) => (event.target.src = "images/missing-image.png")}
        />
        <Card.Body className="news-card-all">
          <Card.Title className={"news-card-source-" + props.bwrColor}>
            {props.sourcetype==='questionable' ? (
              <a href={sourceinfo} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon="exclamation-triangle" size="sm" />
              </a>
            ) : null}{" "}
            {props.source}
          </Card.Title>
          <Card.Title className={"news-card-date-" + props.bwrColor}>
            {publishedFormat(props.published)}
          </Card.Title>
          <Card.Text
            className={"news-card-separator-" + props.bwrColor}
          ></Card.Text>
          {/* { props.thumbnail ? 
            <Card.Text className="news-card-title" style={{height: "300px"}}>
              {title}
            </Card.Text> :  */}
          <Card.Text className="news-card-title">{title}</Card.Text>
          {/* } */}
        </Card.Body>
        {props.sortby == "2" ? (
          <Card.Footer className="news-card-footer">
            {custom_number_format(parseInt(props.fblikes))}{" "}
            <FontAwesomeIcon icon="thumbs-up" size="sm" />
          </Card.Footer>
        ) : props.sortby == "3" ? (
          <Card.Footer className="news-card-footer">
            {custom_number_format(parseInt(props.virality))}{" "}
            <FontAwesomeIcon icon="thumbs-up" size="sm" /> / hr
          </Card.Footer>
        ) : null}
        <NewsModal show={showModal} item={props} />
      </Card>
    ) : (
      // DEFAULT: NewsCard for NEWS page
      <Card className={"newscard-" + props.bwrColor} onClick={() => cardClick()}>
        <Card.Img
          variant="top"
          className="news-card-img-fixed"
          src={props.img}
          onError={(event) => (event.target.style.display = "none")}
        />
        <Card.Body className="news-card-all">
          <Card.Title className={"news-card-source-" + props.bwrColor}>
            {props.sourcetype==='questionable' ? (
              <a href={sourceinfo} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon="exclamation-triangle" size="sm" />
              </a>
            ) : null}{" "}
            {props.source}
          </Card.Title>
          <Card.Title className={"news-card-date-" + props.bwrColor}>
            {publishedFormat(props.published)}
          </Card.Title>
          <Card.Text
            className={"news-card-separator-" + props.bwrColor}
          ></Card.Text>
          <Card.Text className="news-card-title">{title}</Card.Text>
        </Card.Body>
        {/* <Card.Footer className="news-card-footer">
        {props.value}
      </Card.Footer> */}
        {props.sortby == "2" ? (
          <Card.Footer className="news-card-footer">
            {custom_number_format(parseInt(props.fblikes))}{" "}
            <FontAwesomeIcon icon="thumbs-up" size="sm" />
          </Card.Footer>
        ) : null}
        {props.sortby == "3" ? (
          <Card.Footer className="news-card-footer">
            {custom_number_format(parseInt(props.virality))}{" "}
            <FontAwesomeIcon icon="thumbs-up" size="sm" /> / hr
          </Card.Footer>
        ) : null}
        <NewsModal show={showModal} item={props} />
      </Card>
    );
  }

export default NewsCard;