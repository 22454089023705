import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { AuthProvider } from "../auth";
import PrivateRoute from "../PrivateRoute";

import { 
  ShowNews, 
  ShowNewsFactual, 
  ShowNewsStory, 
  ShowAnalytics, 
  ShowHowItWorks, 
  ShowTopics,
  ShowSingleTopic,
  Admin,
  AdminExisting,
  AdminRest } from "../pages";

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "../style/bwr.css"
import {LoginPage} from '../components';

function App() {
  return (
    <AuthProvider>
      <CookiesProvider>
          <Router>
            <Switch>
              <Route path="/" exact component={ShowNews} />
              <Route path="/news" exact component={ShowNews} />
              <Route path="/newsfactual" exact component={ShowNewsFactual} />
              <Route path="/news/:id" exact component={ShowNewsStory} />
              <Route path="/analytics" exact component={ShowAnalytics} />
              <Route path="/howitworks" exact component={ShowHowItWorks} />
              <Route path="/topics" exact component={ShowTopics} />
              <Route path="/topic" exact component={ShowSingleTopic} />
              <PrivateRoute exact path="/admin" component={Admin} />
              <PrivateRoute
                exact
                path="/admin-existing"
                component={AdminExisting}
              />
              <PrivateRoute exact path="/admin-rest" component={AdminRest} />
              <Route path="/login" component={LoginPage} />
            </Switch>
          </Router>
      </CookiesProvider>
    </AuthProvider>
  );
}

export default App;

