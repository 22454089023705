/* eslint-disable no-unused-vars */
import React from "react";
import api from "../api";
import Async from "react-async";
import { Container } from "react-bootstrap";
import MediaQuery from "react-responsive";
import { HeaderBar, Topics } from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

library.add(faSpinner);

function ShowTopics(props) {

  const params = new URLSearchParams(props.location.search);
  var about = params.get("about");

  if(!about) {
    about = "";
  }

  const clusters = () => api.getSomeClusters()
    .catch((err) => {
      console.log(err);
    });
 
  return (
    <Container fluid className="clip-padding topics-padding-top">
      <Async promiseFn={clusters}>
        <Async.Loading>
          <center>
            <FontAwesomeIcon icon="spinner" pulse /> Loading
          </center>
        </Async.Loading>
        <Async.Resolved>
          {(data) =>
            data.data ? (
              <Container
                fluid
                className="clip-padding"
                style={{ position: "relative", top: "-10px" }}
              >
                    <HeaderBar
                      page="topics"
                      about={about}
                      clusters={data.data.data}
                    />
                    <MediaQuery query="(max-width: 768px)">
                      <Topics
                        clusters={data.data.data}
                        cols="3"
                        about={about}
                      />
                    </MediaQuery>
                    <MediaQuery query="(min-width: 769px)">
                      <Topics
                        clusters={data.data.data}
                        cols="7"
                        about={about}
                      />
                    </MediaQuery>
              </Container>
            ) : (
              <Container fluid className="clip-padding bwr-min-width">
                <div className="bwr-error">Nope</div>
              </Container>
            )
          }
        </Async.Resolved>
        <Async.Rejected>{(error) => error.message}</Async.Rejected>
      </Async>
    </Container>
  );

}

export default ShowTopics;
