/* eslint-disable no-unused-vars */
import React from "react";
import api from "../api";
import Async from "react-async";
import { Container, Row, Col } from "react-bootstrap";
import MediaQuery from "react-responsive";
import { HeaderBar, AdminRest3Cols, AdminRest7Cols, AdminNav } from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { AllTopicsProvider } from "../components/AllTopicsContext";


library.add(faSpinner);

function AdminRest(props) {

  const restStories = async () =>
    await api.showNews(3, 5, "internal-shownewsformore").catch((err) => {
      return {};
    });

  return (
    <Container fluid className="clip-padding admin-padding-top">
      <HeaderBar page="admin" />
      <AdminNav page="rest" />
      <Async promiseFn={restStories}>
        <Async.Loading>
          <center>
            <FontAwesomeIcon icon="spinner" pulse /> Loading Unclustered
          </center>
        </Async.Loading>
        <Async.Resolved>
          {(data) =>
            data.data ? (
              <Container fluid className="clip-padding">
                <Row
                  className="clip-margin"
                  style={{ backgroundColor: "#fff7ae" }}
                >
                  <Col className="cluster-header button-admin-wrapper">
                    <Container fluid className="clip-padding">
                      {data.data.data.length} stories found unclustered.
                    </Container>
                  </Col>
                </Row>
                <AllTopicsProvider>
                  <MediaQuery query="(max-width: 768px)">
                    <AdminRest3Cols items={data.data.data} />
                  </MediaQuery>
                  <MediaQuery query="(min-width: 769px)">
                    <AdminRest7Cols items={data.data.data} />
                  </MediaQuery>
                </AllTopicsProvider>
              </Container>
            ) : (
              <Container fluid className="clip-padding bwr-min-width">
                <div className="bwr-error">Nope</div>
              </Container>
            )
          }
        </Async.Resolved>
        <Async.Rejected>{(error) => error.message}</Async.Rejected>
      </Async>
    </Container>
  );

}

export default AdminRest;
