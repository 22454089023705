/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Container, Navbar, Nav, ListGroup, ListGroupItem } from "react-bootstrap";
import { NewsConfigurator, NewsConfig, TopicsConfig } from "./";
import MediaQuery from "react-responsive";
//import onClickOutside from "react-onclickoutside";

function HeaderBar(props) {
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC"
  ];
  const today = new Date();
  const todayDay = days[today.getDay()];
  const todayDate = today.getDate();
  const todayMonth = months[today.getMonth()];
  const todayYear = today.getFullYear();

  // const [open, setOpen] = useState(false);
  // HeaderBar.handleClickOutside = () => setOpen(false);

  // const [scrollingDown, setScrollingDown] = useState(false);
  
  // let y;

  // function handleScroll(newY) {
  //   if ((newY > y) && (newY > 100)) {
  //     //console.log("Scrolling Down");
  //     y = newY;
  //     if (!scrollingDown) {
  //       setScrollingDown(true);
  //     }
  //   } else if (newY < y) {
  //     //console.log("Scrolling Up");
  //     y = newY;
  //     if (scrollingDown) {
  //       setScrollingDown(false);
  //     }
  //   }
  //   y = newY;
  // }

  // onscrolling("vertical", handleScroll);

  return (
    <Container className="clip-padding">
      {/* {scrollingDown && props.page === "topics" ? (
        <Container className="clip-padding">
          <Navbar
            expand="false"
            variant="light"
            fixed="top"
            className="header-bar bwr-min-width clip-padding"
          >
            <SmallHeader clusters={props.clusters}/>
          </Navbar>            
        </Container>
      ) : (
        <Container className="clip-padding"> */}
      <Navbar
        expand="false"
        variant="light"
        fixed="top"
        className="header-bar bwr-min-width clip-padding"
      >
        {/* TOGGLE BUTTON */}
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="clip-border clip-padding"
          style={{ marginLeft: "10px" }}
          //onClick={() => setOpen(!open)}
        />

        {/* LOGO */}

        <MediaQuery query="(max-width: 768px)">
          <Navbar.Brand
            href="/news"
            className="header-logo"
            style={{ marginRight: "-10px", padding: "15px 0px 12px" }}
          >
            <img
              style={{ height: 40, textAlign: "center" }}
              src="images/bwr_logo_small.png"
              alt="Small BWR Logo"
            ></img>
          </Navbar.Brand>
        </MediaQuery>
        <MediaQuery query="(min-width: 769px)">
          <Navbar.Brand
            href="/news"
            className="header-logo"
            style={{ marginRight: "200px", padding: "15px 0px 12px" }}
          >
            <img
              style={{ height: 40, textAlign: "center" }}
              src="images/bwr_logo_large.png"
              alt="Large BWR Logo"
            ></img>
          </Navbar.Brand>
        </MediaQuery>

        {/* DATE */}

        <Navbar.Text className="clip-border" style={{ marginRight: "10px" }}>
          <ListGroup>
            <ListGroupItem className="clip-border clip-padding clip-margins text-emphasize">
              {todayDay}
            </ListGroupItem>
            <ListGroupItem className="clip-border clip-padding clip-margins text-small">
              {todayDate + " " + todayMonth + " " + todayYear}
            </ListGroupItem>
          </ListGroup>
        </Navbar.Text>

        {/* MENU */}
        <Navbar.Collapse id="basic-navbar-nav" 
        //in={open}
        >
          <Nav className="mr-auto text-ui text-center clean-links bottom-space">
            <Nav.Link href="/news">ALL NEWS</Nav.Link>
            <Nav.Link href="/topics">NEWS by TOPIC</Nav.Link>
            {/* <Nav.Link href="/newsfactual">NEWS by RELIABILITY</Nav.Link> */}
            <Nav.Link href="/howitworks">HOW IT WORKS</Nav.Link>
            <Nav.Link>ANALYTICS (COMING SOON)</Nav.Link>
          </Nav>
        </Navbar.Collapse>

        {/* CONFIG */}
        {props.page === "news" ? (
          <NewsConfig
            sortby={props.sortby}
            period={props.period}
            about={props.about}
            eventTypes={["click", "scroll"]}
          />
        ) : props.page === "topics" ? (
          <TopicsConfig about={props.about} />
        ) : null}
      </Navbar>
      {/* </Container>
      )} */}
    </Container>
  );
}

// const clickOutsideConfig = {
//   handleClickOutside: () => HeaderBar.handleClickOutside,
// };

// export default onClickOutside(HeaderBar, clickOutsideConfig);

export default HeaderBar;
