import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function SourceTable3Cols(props) {

	const blueItems = props.items.map((item) =>
    item.bias === 0 || item.bias === 1 ? (
      <div className="hiw-source-list" key={item.id}>
        <a
          href={"http://" + item.source_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.source}
        </a>
      </div>
    ) : null
  );

	const whiteItems = props.items.map((item) =>
    item.bias === 2 || item.bias === 3 || item.bias === 4 ? (
      <div className="hiw-source-list" key={item.id}>
        <a
          href={"http://" + item.source_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.source}
        </a>
      </div>
    ) : null
  );

	const redItems = props.items.map((item) =>
    item.bias === 5 || item.bias === 6 ? (
      <div className="hiw-source-list" key={item.id}>
        <a
          href={"http://" + item.source_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.source}
        </a>
      </div>
    ) : null
  );

	return (
		<Container fluid className="bwr-min-width text-center">
		<Row xs="3" sm="3" className="light-side-padding bottom-space">
			<Col className="clip-padding col-heading-blue">blue</Col>
			<Col className="clip-padding col-heading-white">white</Col>
			<Col className="clip-padding col-heading-red">red</Col>
		</Row>
		<Row xs="3" sm="3" className="light-side-padding">
			<Col className="clip-padding">{blueItems}</Col>
			<Col className="clip-padding">{whiteItems}</Col>
			<Col className="clip-padding">{redItems}</Col>
		</Row>
		</Container>
	);
}

export default SourceTable3Cols;
