import React,{useState} from "react";
import {
  Container,
  Row, Col, Form, Button
} from "react-bootstrap";
import { NewsCard } from ".";

function AdminCluster7Cols(props) {

  const [newName, setNewName] = useState("");
  const [addName, setAddName] = useState("");
  const [message, setMessage] = useState("");

  function handlePublish() {
    if (newName) {
      setMessage("");
      props.publishfunc(props.index,newName);
    } else setMessage("Need a title to add new cluster.");
  }

  function handleAddTo() {
    if((addName) && (addName !== "Select cluster...")) {
      setMessage("");
      props.addtofunc(props.index,addName);
    } else setMessage("Can't add to blank cluster");
  }

  const existingClusters = props.existingClusters;

  function compare(a, b) {
    //console.log(new Date(a.published));
    //console.log(new Date(a.published) > new Date(b.published));
    if (new Date(a.published) > new Date(b.published)) return -1;
    if (new Date(b.published) > new Date(a.published)) return 1;
    return 0;
  }

  //console.log(props.members);

  const sortedMembers = [...props.members].sort(compare);

  return (
    <Container fluid className="bwr-min-width clip-padding">
      <Form>
        <Form.Group inline="true">
          <Row className="light-side-padding message clip-margin">
            {message}
          </Row>
          <div className="cluster-header bwr-bgcolor-dark">
            <Row className="clip-margin">
              <Col className="cluster-header button-admin-wrapper">
                <Button
                  type="button"
                  className="button-admin"
                  onClick={() => props.removefunc(props.index)}
                >
                  Drop Cluster
                </Button>
              </Col>
            </Row>
            <Row className="clip-margin">
              <Col className="cluster-header button-admin-wrapper">
                <Container fluid className="clip-padding">
                  <Form.Control
                    as="select"
                    className="admin-cluster-namefield"
                    value={addName}
                    type="search"
                    placeholder="Select cluster..."
                    onChange={(event) => {
                      setAddName(event.target.value);
                    }}
                    custom
                  >
                    <option key="0">Select cluster...</option>
                    {existingClusters.map((cluster) => (
                      <option key={cluster._id}>{cluster.title}</option>
                    ))}
                  </Form.Control>
                  <Button
                    type="button"
                    className="button-admin"
                    onClick={() => handleAddTo()}
                  >
                    Add To
                  </Button>
                </Container>
              </Col>
            </Row>
            <Row className="clip-margin">
              <Col className="cluster-header button-admin-wrapper">
                <Form.Control
                  defaultValue={props.index}
                  className="admin-cluster-namefield"
                  type="search"
                  placeholder="Name this cluster..."
                  onChange={(event) => {
                    setNewName(event.target.value);
                  }}
                />
                <Button
                  type="button"
                  className="button-admin"
                  onClick={() => handlePublish()}
                >
                  Add New
                </Button>
              </Col>
            </Row>
          </div>
          <Row xs="7" sm="7" className="light-side-padding clip-margin">
            <Col className="clip-padding col-heading-blue">bluest</Col>
            <Col className="clip-padding col-heading-blue">blue</Col>
            <Col className="clip-padding col-heading-white">bluish</Col>
            <Col className="clip-padding col-heading-white">white</Col>
            <Col className="clip-padding col-heading-white">reddish</Col>
            <Col className="clip-padding col-heading-red">red</Col>
            <Col className="clip-padding col-heading-red">reddest</Col>
          </Row>
          <Row xs="7" sm="7" className="light-side-padding clip-margin">
            <Col className="clip-padding">
              {sortedMembers.map((item) =>
                item.bias === 0 ? (
                  <NewsCard
                    key={item.id}
                    value={item._id}
                    bwrColor="blue"
                    source={item.source}
                    url={item.url}
                    title={item.title}
                    img={item.image}
                    fblikes={item.fblikes}
                    published={item.published}
                    virality={item.virality}
                    type="admin"
                    cluster={props.index}
                    dropfunc={props.dropfunc}
                    hidefunc={props.hidefunc}
                  />
                ) : null
              )}
            </Col>
            <Col className="clip-padding">
              {sortedMembers.map((item) =>
                item.bias === 1 ? (
                  <NewsCard
                    key={item.id}
                    value={item._id}
                    bwrColor="blue"
                    source={item.source}
                    url={item.url}
                    title={item.title}
                    img={item.image}
                    fblikes={item.fblikes}
                    published={item.published}
                    virality={item.virality}
                    type="admin"
                    cluster={props.index}
                    dropfunc={props.dropfunc}
                    hidefunc={props.hidefunc}
                  />
                ) : null
              )}
            </Col>
            <Col className="clip-padding">
              {sortedMembers.map((item) =>
                item.bias === 2 ? (
                  <NewsCard
                    key={item.id}
                    value={item._id}
                    bwrColor="white"
                    source={item.source}
                    url={item.url}
                    title={item.title}
                    img={item.image}
                    fblikes={item.fblikes}
                    published={item.published}
                    virality={item.virality}
                    type="admin"
                    cluster={props.index}
                    dropfunc={props.dropfunc}
                    hidefunc={props.hidefunc}
                  />
                ) : null
              )}
            </Col>
            <Col className="clip-padding">
              {sortedMembers.map((item) =>
                item.bias === 3 ? (
                  <NewsCard
                    key={item.id}
                    value={item._id}
                    bwrColor="white"
                    source={item.source}
                    url={item.url}
                    title={item.title}
                    img={item.image}
                    fblikes={item.fblikes}
                    published={item.published}
                    virality={item.virality}
                    type="admin"
                    cluster={props.index}
                    dropfunc={props.dropfunc}
                    hidefunc={props.hidefunc}
                  />
                ) : null
              )}
            </Col>
            <Col className="clip-padding">
              {sortedMembers.map((item) =>
                item.bias === 4 ? (
                  <NewsCard
                    key={item.id}
                    value={item._id}
                    bwrColor="white"
                    source={item.source}
                    url={item.url}
                    title={item.title}
                    img={item.image}
                    fblikes={item.fblikes}
                    published={item.published}
                    virality={item.virality}
                    type="admin"
                    cluster={props.index}
                    dropfunc={props.dropfunc}
                    hidefunc={props.hidefunc}
                  />
                ) : null
              )}
            </Col>
            <Col className="clip-padding">
              {sortedMembers.map((item) =>
                item.bias === 5 ? (
                  <NewsCard
                    key={item.id}
                    value={item._id}
                    bwrColor="red"
                    source={item.source}
                    url={item.url}
                    title={item.title}
                    img={item.image}
                    fblikes={item.fblikes}
                    published={item.published}
                    virality={item.virality}
                    type="admin"
                    cluster={props.index}
                    dropfunc={props.dropfunc}
                    hidefunc={props.hidefunc}
                  />
                ) : null
              )}
            </Col>
            <Col className="clip-padding">
              {sortedMembers.map((item) =>
                item.bias === 6 ? (
                  <NewsCard
                    key={item.id}
                    value={item._id}
                    bwrColor="red"
                    source={item.source}
                    url={item.url}
                    title={item.title}
                    img={item.image}
                    fblikes={item.fblikes}
                    published={item.published}
                    virality={item.virality}
                    type="admin"
                    cluster={props.index}
                    dropfunc={props.dropfunc}
                    hidefunc={props.hidefunc}
                  />
                ) : null
              )}
            </Col>
          </Row>
        </Form.Group>
      </Form>
    </Container>
  );
}

export default AdminCluster7Cols;