import React from "react";
import { Container } from "react-bootstrap";
import { Topics3Cols, Topics7Cols } from ".";

function Topics(props) {

  return (
    <Container
      id="mainstage"
      fluid
      className="clip-padding bwr-min-width text-center"
    >
      {/* {dummy} */}
      {props.clusters.map((item) =>
        item.title.toUpperCase().includes(props.about.toUpperCase()) ? (
          props.cols === "3" ? (
            <Topics3Cols
              key={item.id}
              memberids={item.memberids}
              title={item.title}
              index={item.id}
              published={item.updatedAt}
              virality={item.avgVirality}
              newest={item.newestDate}
              single={false}
            />
          ) : (
            <Topics7Cols
              key={item.id}
              memberids={item.memberids}
              title={item.title}
              index={item.id}
              published={item.updatedAt}
              virality={item.avgVirality}
              newest={item.newestDate}
              single={false}
            />
          )
        ) : null
      )}
    </Container>
  );

}

export default Topics;