import React from "react";
import {
  Container,
  Row, Col, 
} from "react-bootstrap";
import { NewsCard } from "./";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function News3Columns(props) {

  const blueItems = props.items.map((item) =>
    item.bias === 0 || item.bias === 1 ? (
      <NewsCard
        key={item.id}
        value={item._id}
        bwrColor="blue"
        source={item.source}
        url={item.url}
        sourcetype={item.type}
        title={item.title}
        img={item.image}
        fblikes={item.fblikes}
        published={item.published}
        virality={item.virality}
        sortby={props.sortby}
      />
    ) : null
  );

  const whiteItems = props.items.map((item) =>
    item.bias === 2 || item.bias === 3 || item.bias === 4 ? (
      <NewsCard
        key={item.id}
        value={item._id}
        bwrColor="white"
        source={item.source}
        url={item.url}
        sourcetype={item.type}
        title={item.title}
        img={item.image}
        fblikes={item.fblikes}
        published={item.published}
        virality={item.virality}
        sortby={props.sortby}
      />
    ) : null
  );

  const redItems = props.items.map((item) =>
    item.bias === 5 || item.bias === 6 ? (
      <NewsCard
        key={item.id}
        value={item._id}
        bwrColor="red"
        source={item.source}
        url={item.url}
        sourcetype={item.type}
        title={item.title}
        img={item.image}
        fblikes={item.fblikes}
        published={item.published}
        virality={item.virality}
        sortby={props.sortby}
      />
    ) : null
  );
  
  //let toLog = "LOGGING: NEWS 3COLS";
  //console.log(blueItems);

  var columns = [blueItems, whiteItems, redItems];

  function checkHasItems(item) {
    return item !== null;
  }
  
  const blankItems = columns.map((column) => 
    !column.some(checkHasItems) ? <div className='bwr-error'><p>No stories found {'\n'} with {">"}10k <FontAwesomeIcon icon='thumbs-up' size='sm' /></p></div> : null
  );

	return (
    <Container fluid className="bwr-min-width text-center">
      <Row xs="3" sm="3" className="light-side-padding">
        <Col className="clip-padding col-heading-blue">blue</Col>
        <Col className="clip-padding col-heading-white">white</Col>
        <Col className="clip-padding col-heading-red">red</Col>
      </Row>
      <Row xs="3" sm="3" className="light-side-padding">
        <Col className="clip-padding">{blankItems[0] ? blankItems[0] : blueItems}</Col>
        <Col className="clip-padding">{blankItems[1] ? blankItems[1] : whiteItems}</Col>
        <Col className="clip-padding">{blankItems[2] ? blankItems[2] : redItems}</Col>
        {/* <Col className="clip-padding">{blueItems}</Col>
        <Col className="clip-padding">{whiteItems}</Col>
        <Col className="clip-padding">{redItems}</Col> */}
      </Row>
    </Container>
  );
}

export default News3Columns;