/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */

import React, {useContext} from "react";
import {
  Container,
  Card,
  Row, Col, Navbar
} from "react-bootstrap";
import MediaQuery from "react-responsive";
import { AllTopicIDsContext } from "./AllTopicIDsContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

library.add(faChevronLeft);
library.add(faChevronRight);

function SmallHeader(props) {

    const clusters = useContext(AllTopicIDsContext);

    let prevTopic = "";
    let nextTopic = "";

    if(!props.thumbnail && props.cluster) {
      for (let i = 0; i < clusters.length; i++) {
        if (clusters[i].id === props.cluster.id) {
          if (i > 0) {
            prevTopic = clusters[i - 1].id;
          } else {
            prevTopic = clusters[0].id;
          }
          if (i < clusters.length - 1) {
            nextTopic = clusters[i + 1].id;
          } else {
            nextTopic = clusters[clusters.length - 1].id;
          }
        }
      }
    }

    function timeDiffCalc(dateFuture, dateNow) {
      let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

      // calculate days
      const days = Math.floor(diffInMilliSeconds / 86400);
      diffInMilliSeconds -= days * 86400;
      // console.log("calculated days", days);

      // calculate hours
      const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
      diffInMilliSeconds -= hours * 3600;
      // console.log("calculated hours", hours);

      // calculate minutes
      const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
      diffInMilliSeconds -= minutes * 60;
      // console.log("minutes", minutes);

      let difference = "";
      if (days > 0) {
        difference += days === 1 ? `${days} day ` : `${days} days `;
      } else if (hours > 0) {
        difference +=
          hours === 0 || hours === 1 ? `${hours} hr ` : `${hours} hrs `;
      } else if (minutes > 0) {
        difference +=
          minutes === 0 || hours === 1 ? `${minutes} min` : `${minutes} mins`;
      }

      return difference;
    }
    function publishedFormat(date) {
      var formattedDate = "";

      var pubDate = date;
      var nowDate = new Date();

      formattedDate = timeDiffCalc(nowDate, pubDate) + " ago";

      return formattedDate;
    }

    return (
      <Container
        fluid
        className="bwr-min-width text-center clip-padding fill-parent"
      >
        <Navbar
          expand="false"
          variant="light"
          fixed="top"
          className="clip-padding bwr-min-width bwr-bgcolor-white"
        >
          <Container
            fluid
            className="bwr-min-width clip-margin clip-padding fill-parent"
            style={{ marginBottom: "10px" }}
          >
            {!props.cluster ? (
              <div>*</div>
            ) : !props.thumbnail ? (
              <div className="bwr-min-width fill-parent">
                <Card
                  onClick={() => {
                    window.location = "/news";
                  }}
                  style={{ cursor: "pointer" }}
                  className="fill-parent news-configurator-button-colors clip-border-radius"
                >
                  Go back to Front Page
                </Card>
                <div className="bwr-notice-separator fill-parent"></div>
                <Row className="light-side-padding clip-margin fill-parent topic-menu">
                  <Col className="clip-padding fill-parent">
                    <a href={"/topic?id=" + prevTopic}>
                      <FontAwesomeIcon icon="chevron-left" />
                    </a>
                  </Col>
                  <Col className="clip-padding fill-parent">
                    <a
                      href={"/topics"}
                      className="topic-menu"
                      style={{ textDecoration: "none" }}
                    >
                      Topic List
                    </a>
                  </Col>
                  <Col className="clip-padding fill-parent">
                    <a href={"/topic?id=" + nextTopic}>
                      <FontAwesomeIcon icon="chevron-right" />
                    </a>
                  </Col>
                </Row>
              </div>
            ) : null}
            {props.cluster === null ? (
              <div>*</div>
            ) : (
              <div className="fill-parent">
                <Row
                  className="clip-margin fill-parent"
                  style={{ marginTop: "-20px" }}
                >
                  <Col className="clip-padding topic-header">
                    {props.cluster.title}
                  </Col>
                </Row>
                {!props.thumbnail ? (
                  <Row className="clip-padding clip-margin fill-parent">
                    <Col className="clip-padding cluster-date">
                      Newest story from{" "}
                      {publishedFormat(new Date(props.cluster.newestDate))}
                    </Col>
                  </Row>
                ) : null}
                <Row className="medium-padding clip-margin fill-parent">
                  <Col className="news-card-separator-white clip-padding"></Col>
                </Row>
                <MediaQuery query="(max-width: 768px)">
                  <Row
                    className="light-side-padding clip-margin fill-parent"
                    style={{ marginTop: "-15px" }}
                  >
                    <Col className="clip-padding col-heading-blue">blue</Col>
                    <Col className="clip-padding col-heading-white">white</Col>
                    <Col className="clip-padding col-heading-red">red</Col>
                  </Row>
                </MediaQuery>
                <MediaQuery query="(min-width: 769px)">
                  <Row
                    className="light-side-padding clip-margin fill-parent"
                    style={{ marginTop: "-15px" }}
                  >
                    <Col className="clip-padding col-heading-blue">bluest</Col>
                    <Col className="clip-padding col-heading-blue">blue</Col>
                    <Col className="clip-padding col-heading-white">bluish</Col>
                    <Col className="clip-padding col-heading-white">white</Col>
                    <Col className="clip-padding col-heading-white">
                      reddish
                    </Col>
                    <Col className="clip-padding col-heading-red">red</Col>
                    <Col className="clip-padding col-heading-red">reddest</Col>
                  </Row>
                </MediaQuery>
              </div>
            )}
          </Container>
        </Navbar>
      </Container>
    );
}

export default SmallHeader;