/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import api from "../api";
import Async from "react-async";
import { Container } from "react-bootstrap";
import MediaQuery from "react-responsive";
import { HeaderBar, AdminEditClusters, AdminNav } from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";


library.add(faSpinner);

function AdminExisting(props) {
 
  const existingClusters = () =>
    api.getClusters().catch((err) => {
      console.log(err);
    });

  return (
    <Container fluid className="clip-padding admin-padding-top">
      <HeaderBar page="admin" />
      <AdminNav page="existing" />
      <Async promiseFn={existingClusters}>
        <Async.Loading>
          <center>
            <FontAwesomeIcon icon="spinner" pulse /> Loading
          </center>
        </Async.Loading>
        <Async.Resolved>
          {(data) =>
            data.data ? (
              <Container fluid className="clip-padding">
                <MediaQuery query="(max-width: 768px)">
                  <AdminEditClusters items={data.data.data} cols="3" />
                </MediaQuery>
                <MediaQuery query="(min-width: 769px)">
                  <AdminEditClusters items={data.data.data} cols="7" />
                </MediaQuery>
              </Container>
            ) : (
              <Container fluid className="clip-padding bwr-min-width">
                <div className="bwr-error">Nope</div>
              </Container>
            )
          }
        </Async.Resolved>
        <Async.Rejected>{(error) => error.message}</Async.Rejected>
      </Async>
    </Container>
  );

}

export default AdminExisting;
