/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */

import React from "react";
import {
  Container,
  Card,
  Button,
  Form
} from "react-bootstrap";
import MediaQuery from "react-responsive";

function TopicsConfig(props) {
  
  var about = "";
  if(props.about.length>0) {about = props.about.toUpperCase();} else {about="";}

  return (
    <Container fluid className="bwr-min-width text-center clip-padding">
      <Card className="fill-parent news-configurator-button-colors clip-border-radius">
        <MediaQuery query="(max-width: 550px)">
          <Card.Header>
            <Form>
              <div className="button-config-wrapper">
                <Form.Group
                  inline="true"
                  controlId="about"
                  className="news-configurator-table"
                >
                  <Form.Control
                    name="about"
                    defaultValue={about}
                    className="news-configurator-textfield"
                    type="search"
                    placeholder="Search for..."
                  />            
                  <Button type="submit" className="button-main">
                    GO
                  </Button>
                </Form.Group>
              </div>
            </Form>
          </Card.Header>
        </MediaQuery>
        <MediaQuery query="(min-width: 551px)">
          <Card.Header>
            <Form>
              <div className="button-config-wrapper">
                <Form.Group
                  inline="true"
                  controlId="about"
                  className="news-configurator-table"
                >
                  <Form.Control
                    name="about"
                    defaultValue={about}
                    className="news-configurator-textfield"
                    type="text"
                    placeholder="Search for..."
                  />
                  <Button type="submit" className="button-main">
                    GO
                  </Button>
                </Form.Group>
              </div>
            </Form>
          </Card.Header>
        </MediaQuery>
      </Card>
    </Container>
  );
}

export default TopicsConfig;