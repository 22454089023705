import axios from "axios";
//import {id} from "stopword";

const api = axios.create({
  baseURL: "/api" // PROD
  //baseURL: "http://localhost:3000/api" // DEV
});

// PAGE-LINKED
export const showNews = (period, sortby, about) => api.get(`/news`, {params: {period: period, sortby: sortby, about: about}});
export const showNewsFactual = (period,sortby,about) => api.get(`/newsfactual`,{params: {period: period,sortby: sortby,about: about}});
export const showSources = () => api.get('/sources');
export const showNewsStory = (id) => api.get(`/news/${id}`);
export const showAnalytics = () => api.get(`/analytics`);
export const showHowItWorks = () => api.get(`/howitworks`);

// UTILITY
export const getNewstext = (id) => api.get(`/newstext`, { params: { id: id } });
//export const getCleanText = (text) => api.get(`/cleantext`, { params: { text: text } });
export const getCluster = (id) => api.get(`/getcluster`, { params: { id: id } });
export const getClusterIDs = () => api.get(`/getclusterids`);

// ADMIN
export const getUnclustered = () => api.get(`/admin-unclustered`);
export const getClusters = () => api.get(`/admin-getclusters`);
export const getSomeClusters = () => api.get(`/admin-getsomeclusters`);
export const getStory = (story) =>
  api.get(`/admin-getstory`, {
    params: { story: story },
  });
export const getStories = (memberids) =>
  api.get(`/admin-getstories`, {
    params: { memberids: memberids },
  });
export const getStoriesFromCluster = (clusterid) =>
  api.get(`/admin-getstoriesfromcluster`, {
    params: { clusterid: clusterid },
  });
export const getClusterStoriesByBias = (clusterid, bias) =>
  api.get(`/admin-getclusterstoriesbybias`, {
    params: { clusterid: clusterid, bias: bias },
  });
export const getSource = (sourcename) =>
  api.get(`/admin-getsource`, {
    params: { sourcename: sourcename },
  });
export const matchTopic = (title) =>
  api.get(`/admin-matchtopic`, {
    params: { title: title },
  });
export const getNouns = (text) =>
  api.get(`/admin-getnouns`, {
    params: { text: text },
  });
export const getClusterNouns = (id) =>
  api.get(`/admin-getclusternouns`, {
    params: { id: id },
  });
export const getStoryNouns = (id) =>
  api.get(`/admin-getstorynouns`, {
    params: { id: id },
  });
export const addCluster = (payload) => api.post(`/admin-addcluster`, payload);
export const editClusterTitle = (payload) =>
         api.post(`/admin-editclustertitle`, payload);
export const addToCluster = (payload) => api.post(`/admin-addtocluster`, payload);
export const dropFromCluster = (payload) =>
  api.post(`/admin-dropfromcluster`, payload);
export const hideStory = (payload) => api.post(`/admin-hidestory`, payload);
export const deleteCluster = (payload) => api.post(`/admin-deletecluster`, payload);
export const postCluster = (payload) => api.post(`/admin-postcluster`, payload);
//export const updateCluster = (id,payload) => api.put(`/admin-updatecluster/${id}`,payload);
// export const getMovieById = (id) => api.get(`/movie/${id}`);

const apis = {
  showNews,
  showNewsFactual,
  getNewstext,
  // getCleanText,
  getCluster,
  getClusterIDs,
  showSources,
  showNewsStory,
  showAnalytics,
  showHowItWorks,
  getUnclustered,
  getClusters,
  getSomeClusters,
  getStory,
  getStories,
  getStoriesFromCluster,
  getClusterStoriesByBias,
  getSource,
  matchTopic,
  getNouns,
  getClusterNouns,
  getStoryNouns,
  addCluster,
  addToCluster,
  dropFromCluster,
  editClusterTitle,
  hideStory,
  deleteCluster,
  postCluster
};

export default apis;
