import React, { useState } from "react";
import { Modal, Image, Button } from "react-bootstrap";
import api from "../api";
import Async from "react-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";

library.add(faSpinner);
library.add(faThumbsUp);

function custom_number_format(n) {
  var n_format = 0;

  if (n < 1000) {
    // Anything less than a thousand
    n_format = n;
  } else if (n < 1000000) {
    // Anything less than a million
    n_format = (n / 1000).toFixed(1) + "K";
  } else if (n < 1000000000) {
    // Anything less than a billion
    n_format = (n / 1000000).toFixed(1) + "M";
  } else {
    // At least a billion
    n_format = (n / 1000000000).toFixed(1) + "B";
  }

  return n_format;
}

function timeDiffCalc(dateFuture, dateNow) {
  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;
  // console.log("calculated days", days);

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;
  // console.log("calculated hours", hours);

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;
  // console.log("minutes", minutes);

  let difference = "";
  if (days > 0) {
    difference += days === 1 ? `${days} day ` : `${days} days `;
  } else if (hours > 0) {
    difference += hours === 0 || hours === 1 ? `${hours} hr ` : `${hours} hrs `;
  } else if (minutes > 0) {
    difference +=
      minutes === 0 || hours === 1 ? `${minutes} min` : `${minutes} mins`;
  }

  return difference;
}

function publishedFormat(date) {
  var formattedDate = "";

  var pubDate = new Date(date);
  var nowDate = new Date();

  formattedDate = timeDiffCalc(nowDate, pubDate);

  return formattedDate;
}

function NewsModal(props) {

	function buttonClick(url) {
		console.log("LOGGING: NEWS MODAL READONSITE " + item.value);
		window.open(url);
	}

	// eslint-disable-next-line no-unused-vars
	const [show, setShow] = useState(false);
	//console.log("NewsModal: " + props.show);

	let item = props.item;

	//console.log("id = " + item.value);
	const newstext = () => api.getNewstext(item.value);

	return (
    <Modal
      show={props.show}
      onHide={() => setShow(false)}
      animation={false}
      backdropClassName="news-modal-backdrop"
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      className="news-modal"
      centered
    >
      <Modal.Header className="news-modal-header" closeButton>
        <Modal.Title className="news-modal-title">{item.title}</Modal.Title>
      </Modal.Header>
      <Modal.Title className={"news-modal-stats"}>
        {custom_number_format(parseInt(item.fblikes))}{" "}
        <FontAwesomeIcon icon="thumbs-up" size="sm" /> in{" "}
        {publishedFormat(item.published)}
      </Modal.Title>

      <Modal.Title className={"news-modal-source-" + item.bwrColor}>
        {item.source}
      </Modal.Title>

      <Image className="news-modal-image" src={item.img} fluid />

      <Modal.Body className="news-modal-body">
        <Async promiseFn={newstext}>
          <Async.Loading>
            <center>
              <FontAwesomeIcon icon="spinner" pulse /> Loading
            </center>
          </Async.Loading>
          <Async.Resolved>{(data) => data.data.data}</Async.Resolved>
          <Async.Rejected>{(error) => error.message}</Async.Rejected>
        </Async>
      </Modal.Body>
      <Button
        className={"news-modal-button-" + item.bwrColor}
        onClick={() => buttonClick(item.url)}
      >
        Keep reading at {item.source}
      </Button>
    </Modal>
  );
}

export default NewsModal;