import React, { useState, useContext } from "react";
import {
  Container,
  Row, Col, 
} from "react-bootstrap";
import api from "../api";
import { NewsCard } from ".";
import { AllTopicsContext } from "./AllTopicsContext";

function AdminRest3Cols(props) {

  const loadClusters = useContext(AllTopicsContext);

  const [allStories, setAllStories] = useState([...props.items]);

  function hideStory(story) {
    console.log("Hiding story " + story);
    let newAllStories = [...allStories];
    for (let i = 0; i < newAllStories.length; i++) {
      if (newAllStories[i].id === story) {
        console.log("Found it at " + i);
        newAllStories.splice(i, 1);
      }
    }
    setAllStories(newAllStories);
    const result = api.hideStory({ id: story }).catch((err) => {
      console.log(err);
    });
    
    return result;
  }

  function newCluster(id) {
    console.log("Publishing new cluster with new story id " + id);
    let newAllStories = [...allStories];
    for (let i = 0; i < newAllStories.length; i++) {
      if (newAllStories[i].id === id) {
        console.log("Publishing...");
        let toPublish = [];
        toPublish[0] = ">>>DRAFT: " + newAllStories[i].title;
        toPublish[1] = newAllStories[i].id;
        console.log(toPublish);
        // eslint-disable-next-line no-unused-vars
        const result = api.addCluster(toPublish).catch((err) => {
          console.log(err);
        });

        newAllStories.splice(i, 1);
      }
    }
    setAllStories(newAllStories);
  }

  async function addToCluster(index, clustertitle) {
    console.log("Adding story " + index + " to cluster " + clustertitle);

    // const result = await api.getStory(index).catch((err) => {
    //   console.log(err);
    // });

    // let newsItem = result.data.data;

    let toPublish = [clustertitle, index];
    //console.log(toPublish);
    // eslint-disable-next-line no-unused-vars
    const final = api.addToCluster(toPublish).catch((err) => {
      console.log(err);
    });

    let newAllStories = [...allStories];
    for (let i = 0; i < newAllStories.length; i++) {
      if (newAllStories[i].id === index) {
        console.log("Found it at " + i);
        newAllStories.splice(i, 1);
      }
    }
    setAllStories(newAllStories);

    return index;
  }

	return (
    <Container fluid className="bwr-min-width text-center">
      <Row xs="3" sm="3" className="light-side-padding">
        <Col className="clip-padding col-heading-blue">blues</Col>
        <Col className="clip-padding col-heading-white">whites</Col>
        <Col className="clip-padding col-heading-red">reds</Col>
      </Row>
      {/* <Row xs="3" sm="3" className="light-side-padding">
        <Col className="clip-padding">{allStories.map((item) => item.title)}</Col>
        <Col className="clip-padding">{allStories[1].title}</Col>
        <Col className="clip-padding">{allStories[2].title}</Col>
      </Row> */}
        <Container fluid className="clip-padding">
          <Row xs="3" sm="3" className="light-side-padding">
            <Col className="clip-padding">
              {/* {allStories.map((item) =>
                item.bias === 0 || item.bias === 1 ? (
                  <NewsCard
                    key={item.id}
                    value={item._id}
                    bwrColor="blue"
                    source={item.source}
                    url={item.url}
                    title={item.title}
                    img={item.image}
                    fblikes={item.fblikes}
                    published={item.published}
                    virality={item.virality}
                    nouns={item.nouns}
                    sortby={props.sortby}
                    type="admin-rest"
                    existing={loadClusters}
                    hidefunc={hideStory}
                    newfunc={newCluster}
                    addtofunc={addToCluster}
                  />
                ) : null
              )} */}
            </Col>
            <Col className="clip-padding">
              {allStories.map((item) =>
                item.bias === 2 || item.bias === 3 || item.bias === 4 ? (
                  <NewsCard
                    key={item.id}
                    value={item._id}
                    bwrColor="white"
                    source={item.source}
                    url={item.url}
                    title={item.title}
                    img={item.image}
                    fblikes={item.fblikes}
                    published={item.published}
                    virality={item.virality}
                    nouns={item.nouns}
                    sortby={props.sortby}
                    type="admin-rest"
                    existing={loadClusters}
                    hidefunc={hideStory}
                    newfunc={newCluster}
                    addtofunc={addToCluster}
                  />
                ) : null
              )}
            </Col>
            <Col className="clip-padding">
              {allStories.map((item) =>
                item.bias === 5 || item.bias === 6 ? (
                  <NewsCard
                    key={item.id}
                    value={item._id}
                    bwrColor="red"
                    source={item.source}
                    url={item.url}
                    title={item.title}
                    img={item.image}
                    fblikes={item.fblikes}
                    published={item.published}
                    virality={item.virality}
                    nouns={item.nouns}
                    sortby={props.sortby}
                    type="admin-rest"
                    existing={loadClusters}
                    hidefunc={hideStory}
                    newfunc={newCluster}
                    addtofunc={addToCluster}
                  />
                ) : null
              )}
            </Col>
          </Row>
        </Container>
    </Container>
  );
}

export default AdminRest3Cols;