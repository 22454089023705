import React,{useState} from "react";
import api from "../api";
import Async from "react-async";
import {
  Container,
  Row, Col, Form, Button
} from "react-bootstrap";

import { NewsCard, AdminMore3Cols } from ".";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

library.add(faSpinner);

function AdminEditCluster3Cols(props) {

  //console.log("Loading Exists");

  const [newName, setNewName] = useState(props.title);
  const [message, setMessage] = useState("");

  function handleEditTitle() {
    if (newName) {
      setMessage("");
      props.edittitlefunc(props.index, newName);
    } else setMessage("Need a new title for cluster.");
  }

  // function handleAddTo() {
  //   if (addName && addName !== "Select cluster...") {
  //     setMessage("");
  //     props.addtofunc(props.index, addName);
  //   } else setMessage("Can't add to blank cluster");
  // }

  function compare(a, b) {
      if (new Date(a.published) > new Date(b.published)) return -1;
      if (new Date(b.published) > new Date(a.published)) return 1;
      return 0;
  }

  const sortedMembers = [...props.members].sort(compare);

  const moreItems = async () => {
    console.log("Loading more for " + props.index);
    try {
      var searchNouns = "";
      try {
        searchNouns = await api.getClusterNouns(props.index).catch((err) => {console.log(err)});
      } catch(err) {
        console.log(err);
      }
      console.log(searchNouns);
      if (searchNouns.data.success === true) {
        let searchString = props.title + " ";
        for (var nounObj of searchNouns.data.data) {
          searchString += nounObj.value + " ";
        }
        let moreItems = await api.showNews(3, 5, searchString).catch((err) => {console.log(err)});
        if (moreItems.data.success === true) { return moreItems; }
      }
    } catch(err) {
      console.log(err);
    }
  }

  //console.log(moreItems);

  //COLLAPSIBLE
  const [allCollapsed, setAllCollapsed] = useState(true);

  function dropNulls(arrayRef) {
    let array = [];
    // eslint-disable-next-line no-unused-vars
    let i = 0;
    for (let element of arrayRef) {
      if (element === null) {
        i++;
      } else array.push(element);
    }
    return array;
  }

  let mappedblueItems = sortedMembers.map((item) =>
    item.bias === 0 || item.bias === 1 ? (
      <NewsCard
        key={item.id}
        value={item._id}
        bwrColor="blue"
        source={item.source}
        url={item.url}
        title={item.title}
        img={item.image}
        fblikes={item.fblikes}
        published={item.published}
        virality={item.virality}
        type="admin-existing"
        cluster={props.index}
        dropfromfunc={props.dropfromfunc}
      />
    ) : null
  );

  let mappedwhiteItems = sortedMembers.map((item) =>
    item.bias === 2 || item.bias === 3 || item.bias === 4 ? (
      <NewsCard
        key={item.id}
        value={item._id}
        bwrColor="white"
        source={item.source}
        url={item.url}
        title={item.title}
        img={item.image}
        fblikes={item.fblikes}
        published={item.published}
        virality={item.virality}
        type="admin-existing"
        cluster={props.index}
        dropfromfunc={props.dropfromfunc}
      />
    ) : null
  );

  let mappedredItems = sortedMembers.map((item) =>
    item.bias === 5 || item.bias === 6 ? (
      <NewsCard
        key={item.id}
        value={item._id}
        bwrColor="red"
        source={item.source}
        url={item.url}
        title={item.title}
        img={item.image}
        fblikes={item.fblikes}
        published={item.published}
        virality={item.virality}
        type="admin-existing"
        cluster={props.index}
        dropfromfunc={props.dropfromfunc}
      />
    ) : null
  );

  let blueItems = dropNulls(mappedblueItems);
  let whiteItems = dropNulls(mappedwhiteItems);
  let redItems = dropNulls(mappedredItems);

  let firstRow = [blueItems[0], whiteItems[0], redItems[0]];
  //console.log(props);

  let moreCounts = [];
  if (blueItems.length > 1) {
    moreCounts[0] = blueItems.length - 1;
  }
  if (whiteItems.length > 1) {
    moreCounts[1] = whiteItems.length - 1;
  }
  if (redItems.length > 1) {
    moreCounts[2] = redItems.length - 1;
  }

  return (
    <Container fluid className="bwr-min-width clip-padding">
      <Form>
        <Form.Group inline="true">
          <Row className="light-side-padding message clip-margin">
            {message}
          </Row>
          <div className="cluster-header bwr-bgcolor-dark">
            <Row className="clip-margin">
              <Col className="cluster-header button-admin-wrapper">
                <Button
                  type="button"
                  className="button-admin"
                  onClick={() => props.deletefunc(props.index)}
                >
                  Delete Cluster
                </Button>
              </Col>
            </Row>
            <Row className="clip-margin">
              <Col className="cluster-header button-admin-wrapper">
                <Form.Control
                  name="clusterName"
                  defaultValue={props.title}
                  className="admin-cluster-namefield"
                  type="search"
                  placeholder="Name this cluster..."
                  onChange={(event) => {
                    setNewName(event.target.value);
                  }}
                />
                <Button
                  type="button"
                  className="button-admin"
                  onClick={() => handleEditTitle()}
                >
                  Edit Title
                </Button>
              </Col>
            </Row>
            <Row className="clip-margin">
              <Col className="cluster-header button-admin-wrapper">
                <Button
                  type="button"
                  className="button-admin"
                  onClick={() => props.postfunc(props.index)}
                >
                  Post Cluster to Social
                </Button>
              </Col>
            </Row>
          </div>
          <Row xs="3" sm="3" className="light-side-padding clip-margin">
            <Col className="clip-padding col-heading-blue">blue</Col>
            <Col className="clip-padding col-heading-white">white</Col>
            <Col className="clip-padding col-heading-red">red</Col>
          </Row>
          {!allCollapsed ? (
            <Row
              className="medium-padding clip-margin"
              style={{ marginTop: "20px", marginBottom: "-5px"}}
            >
              <Col
                onClick={() => setAllCollapsed(!allCollapsed)}
                className="cluster-toggle"
              >
                Collapse Current Stories
              </Col>
            </Row>
          ) : null}
          {allCollapsed ? (
            <div>
              <Row xs="3" sm="3" className="light-side-padding clip-margin">
                <Col className="clip-padding">{firstRow[0]}</Col>
                <Col className="clip-padding">{firstRow[1]}</Col>
                <Col className="clip-padding">{firstRow[2]}</Col>
              </Row>
              <Row xs="3" sm="3" className="light-side-padding clip-margin">
                {moreCounts[0] ? (
                  <Col className="clip-padding">
                    <div
                      onClick={() => setAllCollapsed(!allCollapsed)}
                      className="more-cluster-blue"
                    >
                      +{moreCounts[0]} more
                    </div>
                  </Col>
                ) : (
                  <Col className="clip-padding">
                    <div className="more-cluster-transparent"></div>
                  </Col>
                )}
                {moreCounts[1] ? (
                  <Col className="clip-padding">
                    <div
                      onClick={() => setAllCollapsed(!allCollapsed)}
                      className="more-cluster-white"
                    >
                      +{moreCounts[1]} more
                    </div>
                  </Col>
                ) : (
                  <Col className="clip-padding">
                    <div className="more-cluster-transparent"></div>
                  </Col>
                )}
                {moreCounts[2] ? (
                  <Col className="clip-padding">
                    <div
                      onClick={() => setAllCollapsed(!allCollapsed)}
                      className="more-cluster-red"
                    >
                      +{moreCounts[2]} more
                    </div>
                  </Col>
                ) : (
                  <Col className="clip-padding">
                    <div className="more-cluster-transparent"></div>
                  </Col>
                )}
              </Row>
            </div>
          ) : (
            <Row xs="3" sm="3" className="light-side-padding clip-margin">
              <Col className="clip-padding">{blueItems}</Col>
              <Col className="clip-padding">{whiteItems}</Col>
              <Col className="clip-padding">{redItems}</Col>
            </Row>
          )}
          {!allCollapsed ? (
            <Row
              className="medium-padding clip-margin"
              style={{ position: "relative", top: "15px" }}
            >
              <Col
                onClick={() => setAllCollapsed(!allCollapsed)}
                className="cluster-toggle"
              >
                Collapse Current Stories
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col>
              <Async promiseFn={moreItems}>
                <Async.Loading>
                  <center>
                    <FontAwesomeIcon icon="spinner" pulse /> Loading
                  </center>
                </Async.Loading>
                <Async.Resolved>
                  {(data) =>
                     ((data!==undefined) && (data.data!==undefined)) ? (
                      <Container fluid className="clip-padding">
                        <AdminMore3Cols
                          moreStories={data.data.data}
                          addtofunc={props.addtofunc}
                          index={props.index}
                          members={props.members}
                          title={props.title}
                        />
                      </Container>
                    ) : (
                      <Container fluid className="clip-padding bwr-min-width">
                        <div className="bwr-error">
                          No suggested stories to add
                        </div>
                      </Container>
                    )
                  }
                </Async.Resolved>
                <Async.Rejected>{(error) => error.message}</Async.Rejected>
              </Async>
            </Col>
          </Row>
        </Form.Group>
      </Form>
    </Container>
  );
}

export default AdminEditCluster3Cols;