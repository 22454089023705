import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

function AdminNewCluster(props) {

  const [newName, setNewName] = useState("");
  const [message, setMessage] = useState("");

  function handlePublish() {
    if (newName) {
      setMessage("");
      props.publishfunc(newName);
    } else setMessage("Need a title to add new cluster.");
  }

	return (
    <Container fluid className="bwr-min-width clip-padding">
      <Row className="light-side-padding message clip-margin">{message}</Row>
      <Row className="clip-margin" style={{ backgroundColor: "#fff7ae" }}>
        <Col className="cluster-header button-admin-wrapper">
          <Container fluid className="clip-padding">
            <Form.Control
              defaultValue={newName}
              className="admin-cluster-selectfield"
              type="search"
              placeholder="Name a new cluster..."
              onChange={(event) => {
                setNewName(event.target.value);
              }}
            />
            <Button
              type="button"
              className="button-admin"
              onClick={() => handlePublish()}
            >
              Add New
            </Button>
          </Container>
        </Col>
      </Row>
      <Row className="clip-margin">
        <Col className="cluster-header button-admin-wrapper">
          <div
            className="text-ui medium-padding"
            style={{ fontSize: "1.0em", marginTop: "5px", marginBottom: "-5px" }}
          >
            Automatically Drafted Clusters:
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminNewCluster;