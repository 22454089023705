/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { NewsModal } from "./";

library.add(faThumbsUp);

function custom_number_format(n) {
  var n_format=0;

  if (n < 1000) {
      // Anything less than a thousand
      n_format = n;
  } else if (n < 1000000) {
      // Anything less than a million
      n_format = (n / 1000).toFixed(1) + "K";
  } else if (n < 1000000000) {
      // Anything less than a billion
      n_format = (n / 1000000).toFixed(1) + "M";
  } else {
      // At least a billion
      n_format = (n / 1000000000).toFixed(1) + "B";
  }

  return n_format;
}

function timeDiffCalc(dateFuture, dateNow) {
  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;
  // console.log("calculated days", days);

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;
  // console.log("calculated hours", hours);

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;
  // console.log("minutes", minutes);

  let difference = "";
  if (days > 0) {
    difference += days === 1 ? `${days} day ` : `${days} days `;
  } else if (hours > 0) {
    difference +=
      hours === 0 || hours === 1 ? `${hours} hr ` : `${hours} hrs `;
  } else if (minutes > 0) {
    difference +=
      minutes === 0 || hours === 1
        ? `${minutes} min`
        : `${minutes} mins`;
  }

  return difference;
}

function publishedFormat(date) {
  var formattedDate = "";

  var pubDate = new Date(date);
  var nowDate = new Date();

  formattedDate = timeDiffCalc(nowDate, pubDate) + " ago"

  return formattedDate;
}

function NewsCardFactual(props) {

  const [showModal, setShowModal] = useState(false);

  function cardClick() {
    if (showModal) {
          console.log("LOGGING: NEWS MODAL CLOSE " + props.value);
        }
    else {
           console.log("LOGGING: NEWS MODAL OPEN " + props.value);
         }
    setShowModal(!showModal);
  }

  let title = "";
  if (props.title.length > 200) {
    title = props.title.substr(0,200);
  } else title = props.title;

  return (
    <Card className="newscard-factual" onClick={() => cardClick()}>
      <Card.Img variant="top" className={"news-card-img-" + props.factual} src={props.img} />
      <Card.Body className="news-card-all">
        <Card.Title className={"news-card-source-" + props.factual}>{props.source}</Card.Title>
        <Card.Text
          className={"news-card-separator-" + props.bwrColor}
        ></Card.Text>
        <Card.Text className={"news-card-title-" + props.factual}>{title}</Card.Text>
      </Card.Body>
      {/* <Card.Footer className="news-card-footer">
        {props.value}
      </Card.Footer> */}
      {props.sortby == "1" ? (
        <Card.Footer className="news-card-footer">
          {publishedFormat(props.published)}
        </Card.Footer>
      ) : null}
      {props.sortby == "2" ? (
        <Card.Footer className="news-card-footer">
          {custom_number_format(parseInt(props.fblikes))}{" "}
          <FontAwesomeIcon icon="thumbs-up" size="sm" />
        </Card.Footer>
      ) : null}
      {props.sortby == "3" ? (
        <Card.Footer className="news-card-footer">
          {custom_number_format(parseInt(props.virality))}{" "}
          <FontAwesomeIcon icon="thumbs-up" size="sm" /> / hr
        </Card.Footer>
      ) : null}
      <NewsModal show={showModal} item={props} />
    </Card>
  );
}

export default NewsCardFactual;