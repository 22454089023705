import React, { useState } from "react";
import api from "../api";
import Async from "react-async";
import { Container } from "react-bootstrap";
import MediaQuery from "react-responsive";
import { HeaderBar, News3Columns, News7Columns } from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useCookies } from "react-cookie";

library.add(faSpinner);

function ShowNews(props) {

  const params = new URLSearchParams(props.location.search);
  var period = params.get("period");
  var sortby = params.get("sortby");
  var about = params.get("about");

  if (!period) {
    period = 2;
  }
  if (!sortby) {
    sortby = 1;
  }
  if (!about) {
    about = "";
  }
  
  const items = () => 
    api.showNews(period, sortby, about)
    .catch((err) => {
      return (
        {/* <Container fluid className="clip-padding news-padding-top">

        </Container> */}
      );
    });
    

  const [show, setShow] = useState(false);
  const [cookies, setCookie] = useCookies(["isFirstLoad"]);

  if (cookies.isFirstLoad === "nope") {
    //console.log("Not First Time");
  } else {
    //console.log("First Time");
    if (!show) setShow(true);
    setCookie("isFirstLoad", "nope", { path: "/" });
  }


    return (
      <Container fluid className="clip-padding news-padding-top">
        <HeaderBar
          page="news"
          period={period}
          sortby={sortby}
          about={about}
          eventTypes={["click", "scroll"]}
        />
        <Async promiseFn={items}>
          <Async.Loading>
            <center>
              <FontAwesomeIcon icon="spinner" pulse /> Loading
            </center>
          </Async.Loading>
          <Async.Resolved>
            {(data) => ( data.data ? (
              <Container fluid className="clip-padding">
                <MediaQuery query="(max-width: 768px)">
                  <News3Columns items={data.data.data} sortby={sortby} />
                </MediaQuery>
                <MediaQuery query="(min-width: 769px)">
                  <News7Columns items={data.data.data} sortby={sortby} />
                </MediaQuery>
              </Container>
            ) : (
                <Container fluid className="clip-padding bwr-min-width">
                  <div className="bwr-error">No stories found with more than 10k <FontAwesomeIcon icon="thumbs-up" size="sm" /></div>
                </Container>
            )
            )}
          </Async.Resolved>
          <Async.Rejected>{(error) => error.message}</Async.Rejected>
        </Async>
        {/* <Toast className="bwr-toast" onClose={() => setShow(false)} show={show}>
          <Toast.Header style={{ "backgroundColor":"#333333", "color": "#FFFFFF", "borderRadius": "3px" }}>
              <a href="https://bluewhitered.org/howitworks">Learn how BWR works</a>
          </Toast.Header>
        </Toast> */}
      </Container>
    );

}

export default ShowNews;
